import React, { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { IconButton, Badge, Typography, List, ListItem, ListItemText, Divider, Box, Button, Popover, Slide, MenuItem, Link } from "@mui/material";
import { Notifications, NotificationsActive, CheckCircle, Warning, Close } from "@mui/icons-material";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';


import { useTranslation } from 'react-i18next';

// GraphQL Query to fetch notifications
const GET_NOTIFICATIONS = gql`
  query GetNotifications($userId: ID!) {
    getNotifications(userId: $userId) {
      notificationId
      userId
      ecgId
      patientCode
      type
      isRead
      createdAt
    }
  }
`;

// GraphQL Mutation to mark a notification as read
const MARK_NOTIFICATION_AS_READ = gql`
  mutation MarkNotificationAsRead($userId: ID!, $notificationId: ID!) {
    markNotificationAsRead(userId: $userId, notificationId: $notificationId) {
      notificationId
      isRead
    }
  }
`;

// GraphQL Mutation to mark all notifications as read
const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllNotificationsAsRead($userId: ID!) {
    markAllNotificationsAsRead(userId: $userId) {
      success
    }
  }
`;

const NotificationsMenu = ({ userId }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage;


  const { data, loading, error, refetch } = useQuery(GET_NOTIFICATIONS, {
    variables: { userId },
  });

  useEffect(() => {
    refetch(); // Refresh notifications when component is mounted
  }, [refetch]);

  const [markAsRead] = useMutation(MARK_NOTIFICATION_AS_READ);
  const [markAllAsRead] = useMutation(MARK_ALL_NOTIFICATIONS_AS_READ);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      await markAsRead({
        variables: { userId, notificationId },
      });
      refetch(); // Refresh notifications
    } catch (err) {
      console.error("Error marking notification as read:", err);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead({ variables: { userId } });
      refetch(); // Refresh notifications
    } catch (err) {
      console.error("Error marking all notifications as read:", err);
    }
  };

  if (loading) return <Typography>Loading notifications...</Typography>;
  if (error) return <Typography>Error loading notifications: {error.message || 'Unexpected error occurred.'}</Typography>;

  if (!userId) {
    return <Typography variant="body2" color="textSecondary">User not authenticated or invalid token.</Typography>;
  }

  const notifications = [...(data?.getNotifications || [])].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 10);
  const unreadCount = notifications.filter((n) => !n.isRead).length;

  const open = Boolean(anchorEl);

  return (
    <div>
      {/* Notification Icon */}
      <IconButton
        color="inherit"
        onClick={handlePopoverOpen}
        aria-controls="notifications-popover"
        aria-haspopup="true"
      >
        <Badge
          badgeContent={unreadCount}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: unreadCount > 0 ? "#f44336" : "#808080", // Rouge si non lu, gris sinon
              color: "white", // Texte blanc pour le badge
            },
          }}
        >
          {unreadCount > 0 ? (
            <NotificationsActive
              sx={{
                color: "#808080", // Rouge pour l'icône avec notifications non lues
              }}
            />
          ) : (
            <Notifications
              sx={{
                color: "#808080", // Gris pour l'icône sans notifications non lues
              }}
            />
          )}
        </Badge>
      </IconButton>

      {/* Notification Popover */}
      <Popover
        id="notifications-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            width: 400,
            borderRadius: 3,
            boxShadow: 3,
            padding: 2,
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Notifications</Typography>
          <IconButton size="small" onClick={handlePopoverClose}>
            <Close />
          </IconButton>
        </Box>
        <Button variant="text" onClick={handleMarkAllAsRead} sx={{ fontSize: "0.8rem", marginBottom: 2 }}>
          Marquer tout comme lu
        </Button>
        <Divider sx={{ marginY: 1 }} />
        <List sx={{ maxHeight: '50vh', overflow: 'auto' }}>
          {notifications.length === 0 && (
            <MenuItem disabled>
              <Typography variant="body2">No notifications</Typography>
            </MenuItem>
          )}
          {notifications.map((notification) => (
            <Slide in direction="up" timeout={300} key={notification.notificationId}>
              <ListItem
                dense
                button
                onClick={() => handleMarkAsRead(notification.notificationId)}
                sx={{ alignItems: 'flex-start', borderRadius: 2, marginBottom: 1 }}
              >
                {notification.type === "urgent" ? (
                  <Warning sx={{ marginRight: 2, color: "#f44336" }} />
                ) : (
                  <CheckCircle sx={{ marginRight: 2, color: "#4caf50" }} />
                )}
                <ListItemText
                  primary={
                    <Typography variant="body1" component="span" fontWeight={notification.isRead ? 'normal' : 'bold'}>
                      {notification.type === 'normal' ? (
                        <span>
                          {t('notifications.normal', { patientCode: notification.patientCode })}{' '}
                          <Link href={`/ecg/${notification.patientCode}/${notification.ecgId}`} underline="hover">
                            {t('notifications.viewDetails')}
                          </Link>
                        </span>
                      ) : (
                        <span>
                          {t('notifications.urgent', { patientCode: notification.patientCode })}{' '}
                          <Link href={`/ecg/${notification.patientCode}/${notification.ecgId}`} underline="hover">
                            {t('notifications.viewDetails')}
                          </Link>
                        </span>
                      )}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {formatDistanceToNow(parseISO(notification.createdAt), { addSuffix: true, locale: language === 'fr' ? fr : enUS })}
                    </Typography>
                  }
                />
              </ListItem>
            </Slide>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default NotificationsMenu;
