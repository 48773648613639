import React from 'react';
import {
    IconButton,
    Box,
} from '@mui/material';


import { ZoomIn, ZoomOut, Replay } from '@mui/icons-material';


const Control = ({ setZoomLevel, setPanOffset }) => {

    const handleZoomIn = () => {
        setZoomLevel((prev) => prev * 1.2);
    };

    const handleZoomOut = () => {
        setZoomLevel((prev) => prev / 1.2);
    };

    const handleResetZoom = () => {
        setZoomLevel(1);
        setPanOffset(0);
    };

    return (
        <Box display="flex" justifyContent="center" marginTop={2}>
            <IconButton onClick={handleZoomIn} color="primary" style={{ marginRight: 8 }}>
                <ZoomIn />
            </IconButton>
            <IconButton onClick={handleZoomOut} color="secondary" style={{ marginRight: 8 }}>
                <ZoomOut />
            </IconButton>
            <IconButton onClick={handleResetZoom} color="default" style={{ marginRight: 8 }}>
                <Replay />
            </IconButton>
        </Box>
    );
};

export default Control;