import React from 'react';
import {
    Box,
} from '@mui/material';



const MeasurementGraphCanva = ({ gridCanvasRef, curveCanvasRef }) => {

    return (
        <Box
            width="calc(100% - 100px)"
            position="relative"
            margin="0 50px"
            display="flex"
            justifyContent="center"
            height="400px"
        >
            {/* Canvas pour le quadrillage papier millimétré */}
            <canvas
                ref={gridCanvasRef}
                width={800}
                height={400}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1
                }}
            />
            {/* Canvas pour la courbe ECG */}
            <canvas
                ref={curveCanvasRef}
                width={800}
                height={400}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 2
                }}
            />
        </Box>
    );
};

export default MeasurementGraphCanva;
