import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import {
    Box,
    FormControlLabel,
    Checkbox,
} from '@mui/material';

import { useTranslation } from 'react-i18next';


import MoveLeftGraphArrow from './MoveLeftGraphArrow';
import MeasurementGraphCanva from './MeasurementGraphCanva';
import MoveRightGraphArrow from './MoveRightGraphArrow';
import QuickLook from './QuickLook';
import Control from './Control';
import Filter from './Filter';
import RPeaks from './RPeaks';

import drawGrid from './DrawGrid';
import drawCurve from './DrawCurve';
import handleRightClickOnCurve from './RightClick';

const VoltageMeasurementsGraph = ({ voltageMeasurements, filteredVoltageMeasurements, rPeaks, setRPeaks, filteredRPeaks, qrsComplexes, pPeaks, tPeaks, samplingFrequency }) => {

    const { t } = useTranslation();

    const curveCanvasRef = useRef(null);
    const gridCanvasRef = useRef(null);

    // État pour contrôler l'affichage de la version filtrée
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [showQRS, setShowQRS] = useState(false);
    const [showRPeaks, setShowRpeaks] = useState(false);
    const [showPWave, setShowPWave] = useState(false);
    const [showTWave, setShowTWave] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [panOffset, setPanOffset] = useState(0);

    const [draggingRPeak, setDraggingRPeak] = useState(false);
    const [selectedRPeakIndex, setSelectedRPeakIndex] = useState(null);

    // Préparation des données
    const dataPoints = showFilteredData
        ? filteredVoltageMeasurements.map((item) => item.voltage)
        : voltageMeasurements.map((item) => item.voltage);

    const rPeakDataPoints = showFilteredData ? filteredRPeaks : rPeaks;

    // Définir highlightedSegments en utilisant useMemo
    const highlightedSegments = useMemo(() => {
        const segments = [];

        if (showQRS && qrsComplexes) {
            qrsComplexes.forEach((segment, index) => {
                if (segment.q_onset !== undefined && segment.s_offset !== undefined) {
                    const rangeStart = segment.q_onset / samplingFrequency;
                    const rangeEnd = segment.s_offset / samplingFrequency;

                    segments.push({
                        range: [rangeStart, rangeEnd],
                        backgroundColor: 'rgba(109, 157, 230, 0.8)', // Couleur pour QRS
                    });
                }
            });
        }

        if (showPWave && pPeaks) {
            pPeaks.forEach((segment) => {
                if (segment.onset !== undefined && segment.offset !== undefined) {
                    segments.push({
                        range: [segment.onset / samplingFrequency, segment.offset / samplingFrequency],
                        backgroundColor: 'rgba(255, 109, 120, 0.8)',
                    });
                }
            });
        }

        if (showTWave && tPeaks) {
            tPeaks.forEach((segment) => {
                if (segment.onset !== undefined && segment.offset !== undefined) {
                    segments.push({
                        range: [segment.onset / samplingFrequency, segment.offset / samplingFrequency],
                        backgroundColor: 'rgba(110, 225, 165, 0.8)', // Couleur pour T
                    });
                }
            });
        }

        return segments;
    }, [showQRS, qrsComplexes, showPWave, pPeaks, showTWave, tPeaks, samplingFrequency]);


    useEffect(() => {
        // Dessiner le quadrillage lors du montage initial ou du changement de zoom
        const gridCanvas = gridCanvasRef.current;
        const gridCtx = gridCanvas.getContext('2d');
        drawGrid(gridCtx, gridCanvas.width, gridCanvas.height, zoomLevel, samplingFrequency);
    }, [samplingFrequency, zoomLevel]); // La grille est redessinée uniquement quand `zoomLevel` change

    useEffect(() => {
        // Dessiner la courbe
        const curveCanvas = curveCanvasRef.current;
        const curveCtx = curveCanvas.getContext('2d');
        drawCurve(curveCtx, curveCanvas.width, curveCanvas.height,
            zoomLevel, samplingFrequency, panOffset, dataPoints,
            showRPeaks, highlightedSegments, rPeakDataPoints);

    }, [zoomLevel, samplingFrequency, panOffset, dataPoints,
        showRPeaks, highlightedSegments, rPeakDataPoints]);


    const handleMouseDrag = useCallback((event) => {
        if (event.buttons !== 1) return;
        setPanOffset((prev) => prev - 4 * event.movementX / zoomLevel);
    }, [zoomLevel]);

    useEffect(() => {
        const curveCanvas = curveCanvasRef.current;
        curveCanvas.addEventListener('mousemove', handleMouseDrag);

        return () => {
            curveCanvas.removeEventListener('mousemove', handleMouseDrag);
        };
    }, [zoomLevel, handleMouseDrag]);

    useEffect(() => {
        const curveCanvas = curveCanvasRef.current;
        const handleRightClickWithParams = (event) => handleRightClickOnCurve(event, samplingFrequency, curveCanvasRef, rPeaks, panOffset, zoomLevel, setRPeaks);

        curveCanvas.addEventListener('contextmenu', handleRightClickWithParams);
        return () => {
            curveCanvas.removeEventListener('contextmenu', handleRightClickWithParams);
        };
    }, [samplingFrequency, curveCanvasRef, rPeaks, panOffset, zoomLevel, setRPeaks]);


    const handleMouseDownOnCurve = useCallback((event) => {
        const curveCanvas = curveCanvasRef.current;
        const rect = curveCanvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;

        // Déterminer si un point R-peak est cliqué
        rPeaks.forEach((peak, index) => {
            const x = 50 + (peak.time - panOffset / samplingFrequency) * samplingFrequency * ((curveCanvas.width - 50) / (5 * samplingFrequency / zoomLevel));
            const y = curveCanvas.height * 0.5 - (peak.voltage * 1000 * (curveCanvas.height / 4));

            if (Math.abs(mouseX - x) < 5 && Math.abs(mouseY - y) < 5) {
                setDraggingRPeak(true);
                setSelectedRPeakIndex(index);
            }
        });
    }, [rPeaks, panOffset, samplingFrequency, zoomLevel]);

    const handleMouseMoveOnCurve = useCallback((event) => {
        if (!draggingRPeak || selectedRPeakIndex === null) return;

        const curveCanvas = curveCanvasRef.current;
        const rect = curveCanvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;

        const visibleDuration = 5 / zoomLevel; // Durée visible sur l'axe x, ajustée par le niveau de zoom
        const timePerPixel = visibleDuration / (curveCanvas.width - 50); // Temps représenté par chaque pixel
        const time = (mouseX - 50) * timePerPixel + (panOffset / samplingFrequency);

        const voltageRange = 4; // Plage de -2mV à 2mV
        const voltagePerPixel = voltageRange / curveCanvas.height; // Voltage représenté par chaque pixel
        const voltage = (curveCanvas.height * 0.5 - mouseY) * voltagePerPixel;

        // Mettre à jour la position du R-peak en cours de déplacement
        setRPeaks((prev) =>
            prev.map((peak, index) => (index === selectedRPeakIndex ? { ...peak, time, voltage } : peak))
        );
    }, [draggingRPeak, selectedRPeakIndex, panOffset, samplingFrequency, zoomLevel, setRPeaks]);

    const handleMouseUpOnCurve = useCallback(() => {
        setDraggingRPeak(false);
        setSelectedRPeakIndex(null);
    }, []);

    useEffect(() => {
        const curveCanvas = curveCanvasRef.current;

        if (curveCanvas) {
            curveCanvas.addEventListener('mousedown', handleMouseDownOnCurve);
            curveCanvas.addEventListener('mousemove', handleMouseMoveOnCurve);
            curveCanvas.addEventListener('mouseup', handleMouseUpOnCurve);

            // Nettoyer les événements lors du démontage
            return () => {
                curveCanvas.removeEventListener('mousedown', handleMouseDownOnCurve);
                curveCanvas.removeEventListener('mousemove', handleMouseMoveOnCurve);
                curveCanvas.removeEventListener('mouseup', handleMouseUpOnCurve);
            };
        }
    }, [handleMouseDownOnCurve, handleMouseMoveOnCurve, handleMouseUpOnCurve]);


    return (
        <Box display="flex" width="100%" height="500px">
            <Box
                width="80%"
                position="relative"
                display="flex"
                alignItems="center"
                height="400px"
                marginTop="20px"
            >
                <MoveLeftGraphArrow setPanOffset={setPanOffset} />

                <MeasurementGraphCanva
                    gridCanvasRef={gridCanvasRef}
                    curveCanvasRef={curveCanvasRef}
                />

                <MoveRightGraphArrow setPanOffset={setPanOffset} />
            </Box>

            <Box width="20%" display="flex" flexDirection="column" alignItems="left">
                <QuickLook
                    dataPoints={dataPoints}
                    samplingFrequency={samplingFrequency}
                    zoomLevel={zoomLevel}
                    panOffset={panOffset}
                />

                <Filter
                    setShowFilteredData={setShowFilteredData}
                    showFilteredData={showFilteredData}
                />

                <RPeaks
                    showRPeaks={showRPeaks}
                    setShowRpeaks={setShowRpeaks}
                />

                <FormControlLabel
                    control={<Checkbox checked={showQRS} onChange={(e) => setShowQRS(e.target.checked)} />}
                    label={t('ecg.display_qrs_complex')}
                />
                <FormControlLabel
                    control={<Checkbox checked={showPWave} onChange={(e) => setShowPWave(e.target.checked)} />}
                    label={t('ecg.display_p_wave')}
                />
                <FormControlLabel
                    control={<Checkbox checked={showTWave} onChange={(e) => setShowTWave(e.target.checked)} />}
                    label={t('ecg.display_t_wave')}
                />

                < Control
                    setPanOffset={setPanOffset}
                    setZoomLevel={setZoomLevel}
                />
            </Box>

        </Box>
    );
};

export default VoltageMeasurementsGraph;
