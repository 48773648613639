const handleRightClickOnCurve = (event, samplingFrequency, curveCanvasRef, rPeaks, panOffset, zoomLevel, setRPeaks) => {
    console.log('Clic droit détecté');  // Vérifier que la fonction est bien appelée
    // Empêcher l'affichage du menu contextuel par défaut
    event.preventDefault();

    const curveCanvas = curveCanvasRef.current;
    const rect = curveCanvas.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    // Déterminer si un point R-peak est cliqué
    let clickedRPeakIndex = -1;
    rPeaks.forEach((peak, index) => {
        const x = 50 + (peak.time - panOffset / samplingFrequency) * samplingFrequency * ((curveCanvas.width - 50) / (5 * samplingFrequency / zoomLevel));
        const y = curveCanvas.height * 0.5 - (peak.voltage * 1000 * (curveCanvas.height / 4));

        // Si la distance entre le clic et le point est suffisamment proche, on considère que le point est sélectionné
        if (Math.abs(mouseX - x) < 5 && Math.abs(mouseY - y) < 5) {
            clickedRPeakIndex = index;
            console.log(peak.time)
            console.log(peak.voltage)
        }
    });

    // Créer un menu contextuel personnalisé
    const contextMenu = document.createElement('div');
    contextMenu.style.position = 'fixed';  // Utiliser 'fixed' pour garantir que le menu est correctement positionné
    contextMenu.style.top = `${event.clientY}px`;
    contextMenu.style.left = `${event.clientX}px`;
    contextMenu.style.backgroundColor = 'white';
    contextMenu.style.border = '1px solid #ccc';
    contextMenu.style.padding = '8px';
    contextMenu.style.zIndex = '9999';  // Utiliser un z-index élevé pour garantir que le menu est au-dessus des autres éléments
    contextMenu.style.boxShadow = '0px 4px 8px rgba(0,0,0,0.1)';
    contextMenu.style.minWidth = '120px';
    contextMenu.style.cursor = 'pointer';

    if (clickedRPeakIndex !== -1) {
        // Ajouter l'option de suppression si un R-peak est détecté
        const deleteOption = document.createElement('div');
        deleteOption.innerText = 'Supprimer R-peak';
        deleteOption.style.padding = '4px';
        deleteOption.onclick = () => {
            setRPeaks((prev) => prev.filter((_, index) => index !== clickedRPeakIndex));
            document.body.removeChild(contextMenu);
        };
        contextMenu.appendChild(deleteOption);
    } else {
        // Ajouter l'option d'ajout si aucun R-peak n'est détecté
        const addOption = document.createElement('div');
        addOption.innerText = 'Ajouter R-peak';
        addOption.style.padding = '4px';
        addOption.style.cursor = 'pointer';
        addOption.onclick = () => {
            // Calculer la valeur du temps à partir de la position du clic sur le canvas
            const visibleDuration = 5 / zoomLevel; // Durée visible sur l'axe x, ajustée par le niveau de zoom
            const timePerPixel = visibleDuration / (curveCanvas.width - 50); // Temps représenté par chaque pixel
            const time = (mouseX - 50) * timePerPixel + (panOffset / samplingFrequency);

            // Calculer la valeur de la tension à partir de la position du clic sur le canvas
            const voltageRange = 4; // Plage de -2mV à 2mV
            const voltagePerPixel = voltageRange / curveCanvas.height; // Voltage représenté par chaque pixel
            const voltage = (curveCanvas.height * 0.5 - mouseY) * voltagePerPixel / 1000;
            console.log(time)
            console.log(voltage)

            // Ajouter le nouveau point R-peak
            setRPeaks((prev) => {
                const updatedPeaks = [...prev, { time, voltage }];
                console.log('Nouveau R-peak ajouté:', { time, voltage });
                return updatedPeaks;
            });
            document.body.removeChild(contextMenu);
        };
        contextMenu.appendChild(addOption);
    }

    // Ajouter le menu contextuel au document
    document.body.appendChild(contextMenu);
    console.log('Menu contextuel ajouté au document', contextMenu);

    // Supprimer le menu contextuel lorsqu'on clique ailleurs
    const removeContextMenu = (e) => {
        if (!contextMenu.contains(e.target)) {
            if (contextMenu.parentElement) {
                contextMenu.parentElement.removeChild(contextMenu);
            }
            document.removeEventListener('click', removeContextMenu);
        }
    };

    document.addEventListener('click', removeContextMenu);
};
export default handleRightClickOnCurve;