const drawCurve = (
    ctx,
    width,
    height,
    zoomLevel,
    samplingFrequency,
    panOffset, dataPoints,
    showRPeaks, highlightedSegments, rPeakDataPoints) => {
    ctx.clearRect(0, 0, width, height);

    // Décalage horizontal pour éviter la superposition sur les labels Y
    const xOffset = 50;

    // Durée visible de la courbe (5 secondes par défaut, ajustée par le niveau de zoom)
    const visibleDuration = 5 / zoomLevel; // En secondes

    // Calculer le nombre de points visibles
    const totalPointsVisible = Math.floor(visibleDuration * samplingFrequency);

    // Échelle horizontale pour ajuster la largeur totale du graphique
    const xScale = (width - xOffset) / totalPointsVisible;

    // Fixer les limites pour l'axe Y (de -2 mV à 2 mV)
    const yMin = -2;  // -2 mV
    const yMax = 2;   // 2 mV

    // Échelle verticale ajustée pour que l'axe Y aille de yMin à yMax
    const yScale = height / (yMax - yMin);

    // Positionner le 0V au milieu
    const yOffset = height * (yMax / (yMax - yMin));

    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;
    ctx.beginPath();

    // Calculer l'indice de départ et de fin en fonction du `panOffset`
    const startIndex = Math.max(0, Math.floor(panOffset));
    const endIndex = Math.min(dataPoints.length, startIndex + totalPointsVisible);

    // Dessiner la courbe ECG
    dataPoints.slice(startIndex, endIndex).forEach((point, index) => {
        // Prendre en compte l'offset de panoramique et le décalage fixe (xOffset)
        const x = xOffset + ((index + startIndex - panOffset) * xScale);
        const y = yOffset - (point * 1000 * yScale); // Conversion de V à mV et ajustement avec yOffset

        if (index === 0) {
            ctx.moveTo(x, y);
        } else {
            ctx.lineTo(x, y);
        }
    });
    ctx.stroke();

    // Dessiner les points R-Peaks si activé
    if (showRPeaks) {
        ctx.fillStyle = 'red';
        rPeakDataPoints.forEach((peak) => {
            const time = peak.time; // Convertir le temps en secondes

            // Calcul de la position en tenant compte du `panOffset` et de la durée visible
            if (time < panOffset / samplingFrequency || time > (panOffset / samplingFrequency + visibleDuration)) return;

            // Convertir le temps en position x
            const adjustedTime = time - panOffset / samplingFrequency; // Ajuster le temps en fonction du `panOffset`
            const x = xOffset + adjustedTime * samplingFrequency * xScale; // Calculer la position x sur le canevas

            // Calculer la position y (valeur du voltage)
            const y = yOffset - (peak.voltage * 1000 * yScale); // Conversion de V à mV et ajustement avec yOffset

            // Dessiner le point R-peak
            ctx.beginPath();
            ctx.arc(x, y, 4, 0, 2 * Math.PI);
            ctx.fill();
        });
    }

    // Surligner les segments QRS, P, T si activés
    highlightedSegments.forEach((segment) => {
        const xStart = xOffset + ((segment.range[0] - panOffset / samplingFrequency) * samplingFrequency) * xScale;
        const xEnd = xOffset + ((segment.range[1] - panOffset / samplingFrequency) * samplingFrequency) * xScale;
        ctx.fillStyle = segment.backgroundColor;
        ctx.fillRect(xStart, 0, xEnd - xStart, height);
    });

    // Dessiner les labels de l'axe X (temps en secondes)
    ctx.fillStyle = 'black';
    ctx.font = '12px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'top';

    for (let i = 0; i <= visibleDuration * 5; i++) { // Multiplié par 5 car 5 x 0.2s = 1s
        const time = (panOffset / samplingFrequency) + i * 0.2; // Calculer le temps en fonction de 0.2s d'intervalle
        const xLabel = xOffset + i * 0.2 * samplingFrequency * xScale;

        if (xLabel >= xOffset && xLabel <= width) {
            ctx.save(); // Sauvegarder l'état du contexte avant la rotation
            ctx.translate(xLabel, height - 20); // Déplacer le point d'origine au niveau où le texte sera dessiné
            ctx.rotate(-Math.PI / 2); // Faire une rotation de 90° dans le sens antihoraire
            ctx.fillText(`${time.toFixed(1)} s`, 0, 0); // Dessiner le texte à l'origine de la nouvelle position
            ctx.restore(); // Restaurer l'état initial du contexte après avoir dessiné le texte
        }
    }
};

export default drawCurve;
