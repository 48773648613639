import React, { useState, useEffect } from 'react';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import UserPool from '../UserPool';
import { TextField, Button, Box, MenuItem, Select, InputLabel, FormControl, InputAdornment } from '@mui/material';
import { AccountCircle, Email, Lock, LocationCity, Home } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { getCode, getData } from 'country-list';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'libphonenumber-js';

const RegistrationForm = ({ isRegistering, setIsRegistering, setError, email, setEmail, password, setPassword }) => {

    const { t } = useTranslation();

    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [medicalRole, setMedicalRole] = useState('General Practitioner');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [postalCodeError, setPostalCodeError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [countryCode, setCountryCode] = useState('');

    const countryData = getData();

    useEffect(() => {
        fetch('https://ipapi.co/json/')
            .then((res) => res.json())
            .then((data) => {
                if (data && data.country_code) {
                    setCountryCode(data.country_code.toLowerCase());
                    setCountry(getCode(data.country_code));
                }
            })
            .catch(() => {
                console.log('Erreur lors de la récupération de la géolocalisation');
            });
    }, []);

    const validateForm = () => {
        let isValid = true;
        setError('');
        setPhoneNumberError('');

        // Validation de l'email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError(t('login.invalidEmail'));
            isValid = false;
        } else {
            setEmailError('');
        }

        // Validation du numéro de téléphone
        if (!isValidPhoneNumber(phoneNumber)) {
            setPhoneNumberError(t('login.invalidPhoneNumber'));
            isValid = false;
        } else {
            setPhoneNumberError('');
        }

        // Validation du code postal
        const postalCodeRegex = /^\d{5}$/;
        if (!postalCodeRegex.test(postalCode)) {
            setPostalCodeError(t('login.invalidPostalCode'));
            isValid = false;
        } else {
            setPostalCodeError('');
        }

        // Validation du mot de passe
        if (password.length < 8) {
            setPasswordError(t('login.passwordTooShort'));
            isValid = false;
        } else {
            setPasswordError('');
        }

        // Validation de la confirmation du mot de passe
        if (isRegistering && password !== confirmPassword) {
            setConfirmPasswordError(t('login.passwordsDontMatch'));
            isValid = false;
        } else {
            setConfirmPasswordError('');
        }

        return isValid;
    };

    const handleSignUp = (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        const attributeList = [
            new CognitoUserAttribute({ Name: 'given_name', Value: givenName }),
            new CognitoUserAttribute({ Name: 'family_name', Value: familyName }),
            new CognitoUserAttribute({ Name: 'phone_number', Value: `+${phoneNumber}` }),
            new CognitoUserAttribute({ Name: 'address', Value: address }),
            new CognitoUserAttribute({ Name: 'custom:postalCode', Value: postalCode }),
            new CognitoUserAttribute({ Name: 'custom:city', Value: city }),
            new CognitoUserAttribute({ Name: 'custom:country', Value: country }),
            new CognitoUserAttribute({ Name: 'custom:medicalRole', Value: medicalRole }),
            new CognitoUserAttribute({ Name: 'email', Value: email }),
        ];

        UserPool.signUp(email, password, attributeList, null, (err, result) => {
            if (err) {
                setError(err.message || JSON.stringify(err));
                console.log(err)
                return;
            }
            setIsRegistering(false);
        });
    };

    return (
        <Box component="form" onSubmit={handleSignUp} sx={{ mt: 1, width: '90%' }}>
            {phoneNumberError && (
                <Box sx={{ color: 'error.main', mb: 2 }}>
                    {phoneNumberError}
                </Box>
            )}
            <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('login.firstName')}
                    value={givenName}
                    onChange={(e) => setGivenName(e.target.value)}
                    slotProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle color="primary" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('login.lastName')}
                    value={familyName}
                    onChange={(e) => setFamilyName(e.target.value)}
                    slotProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle color="primary" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}
                />
            </Box>

            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={t('login.email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                slotProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Email color="primary" />
                        </InputAdornment>
                    ),
                }}
                error={!!emailError}
                helperText={emailError}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '16px',
                    },
                  }}
            />

            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={t('login.address')}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                slotProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Home color="primary" />
                        </InputAdornment>
                    ),
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '16px',
                    },
                  }}
            />

            <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('login.postalCode')}
                    value={postalCode}
                    onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        setPostalCode(value);
                    }}
                    slotProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Home color="primary" />
                            </InputAdornment>
                        ),
                    }}
                    error={!!postalCodeError}
                    helperText={postalCodeError}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('login.city')}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    slotProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationCity color="primary" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}
                />
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                    <PhoneInput
                        country={countryCode}
                        value={phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`}
                        onChange={(value) => {
                            const formattedValue = value.startsWith('+') ? value : `+${value}`;
                            setPhoneNumber(formattedValue);

                            // Validation du numéro de téléphone après formatage
                            if (isValidPhoneNumber(formattedValue)) {
                                setPhoneNumberError('');
                            } else {
                                setPhoneNumberError(t('login.invalidPhoneNumber'));
                            }
                        }}
                        inputProps={{
                            name: 'phone',
                            required: true,
                        }}
                        disableDropdown={false}
                        preferredCountries={['us', 'fr', 'gb']}
                        containerStyle={{
                            marginTop: '15px',
                            marginBottom: '15px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            height: '56px',
                        }}
                        inputStyle={{
                            width: '100%',
                            height: '100%',
                            padding: '16.5px 14px 16.5px 48px',
                            boxSizing: 'border-box',
                            borderRadius: '16px',
                            border: '1px solid #ccc',
                        }}
                        buttonStyle={{
                            position: 'absolute',
                            left: 10,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            border: 'none',
                            background: 'none',
                            paddingRight: '10px',
                        }}
                        dropdownStyle={{
                            zIndex: 9999,
                        }}
                    />
                </Box>

                <FormControl fullWidth margin="normal" sx={{ flex: 1 }}>
                    <InputLabel>{t('login.country')}</InputLabel>
                    <Select
                        value={country}
                        onChange={(e) => {
                            setCountry(e.target.value);
                        }}
                        label={t('login.country')}
                        sx={{ borderRadius: '16px' }}
                    >
                        {countryData.map(({ name, code }) => (
                            <MenuItem key={code} value={name}>{name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <FormControl fullWidth margin="normal">
                <InputLabel>{t('login.medicalRole')}</InputLabel>
                <Select
                    value={medicalRole}
                    onChange={(e) => setMedicalRole(e.target.value)}
                    label={t('login.medicalRole')}
                    sx={{ borderRadius: '16px' }}
                >
                    <MenuItem value="Nurse">{t('login.nurse')}</MenuItem>
                    <MenuItem value="General Practitioner">{t('login.generalPractitioner')}</MenuItem>
                    <MenuItem value="Cardiologist">{t('login.cardiologist')}</MenuItem>
                    <MenuItem value="Electrophysiologist">{t('login.electrophysiologist')}</MenuItem>
                    <MenuItem value="Other Specialist">{t('login.otherSpecialist')}</MenuItem>
                </Select>
            </FormControl>

            <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('login.password')}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    slotProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock color="primary" />
                            </InputAdornment>
                        ),
                    }}
                    error={!!passwordError}
                    helperText={passwordError}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}
                />
                {isRegistering && (
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label={t('login.confirmPassword')}
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        slotProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock color="primary" />
                                </InputAdornment>
                            ),
                        }}
                        error={!!confirmPasswordError}
                        helperText={confirmPasswordError}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '16px',
                            },
                          }}
                    />
                )}
            </Box>
            <Button type="submit" fullWidth variant="contained" color="success" sx={{ mt: 3, mb: 2, borderRadius: '16px' }}>
                {t('login.register')}
            </Button>
        </Box>
    );
};

export default RegistrationForm;
