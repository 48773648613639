import React from 'react';
import {
    IconButton,
} from '@mui/material';


import { ChevronRight } from '@mui/icons-material';


const MoveRightGraphArrow = ({ setPanOffset }) => {

    const handlePanRight = () => {
        setPanOffset((prev) => prev + 200);
    };

    return (
        <IconButton
            onClick={handlePanRight}
            style={{
                position: 'absolute',
                right: 10,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 10
            }}
        >
            <ChevronRight />
        </IconButton>
    );
};
export default MoveRightGraphArrow;