import React, { useRef, useEffect, useCallback } from 'react';


const QuickLook = ({ dataPoints, samplingFrequency, zoomLevel, panOffset }) => {

    const quicklookCanvasRef = useRef(null);

    const drawQuicklook = useCallback((ctx, width, height) => {
        // Nettoyer le canvas
        ctx.clearRect(0, 0, width, height);

        // Dessiner l'aperçu de la courbe complète
        const totalDataPoints = dataPoints.length;
        const xScale = width / totalDataPoints; // Échelle horizontale sur la largeur du Quicklook
        const voltageMax = Math.max(...dataPoints);
        const voltageMin = Math.min(...dataPoints);
        const yScale = height / ((voltageMax - voltageMin) * 100000 / 10); // Conversion de V à mV
        const yOffset = height * 0.5; // Positionner le 0V au milieu

        ctx.strokeStyle = 'gray';
        ctx.lineWidth = 1;
        ctx.beginPath();

        dataPoints.forEach((point, index) => {
            const x = index * xScale;
            const y = yOffset - ((point * 1000) * yScale); // Conversion de V à mV

            if (index === 0) {
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
        });
        ctx.stroke();

        // Durée totale des données en secondes
        const totalDuration = totalDataPoints / samplingFrequency;

        // Durée visible dans la courbe principale (5 secondes ajustée par le niveau de zoom)
        const visibleDuration = 5 / zoomLevel; // Durée visible (en secondes) ajustée par le zoom

        // Temps de début de la portion visible en secondes (en fonction de panOffset)
        const visibleStartTime = panOffset / samplingFrequency; // Position de départ de la portion visible (en secondes)

        // Calcul de la position et de la largeur du cadre visible dans le Quicklook
        const visibleStart = (visibleStartTime / totalDuration) * width; // Position de départ sur le canvas du Quicklook
        const visibleWidth = (visibleDuration * samplingFrequency / totalDataPoints) * width; // Largeur sur le canvas du Quicklook

        // Assurer que visibleStart et visibleWidth sont dans les limites du canvas
        const boundedVisibleStart = Math.max(0, Math.min(visibleStart, width));
        const boundedVisibleWidth = Math.max(0, Math.min(visibleWidth, width - boundedVisibleStart));

        // Dessiner le cadre bleu pour indiquer la portion visible
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.strokeRect(boundedVisibleStart, 0, boundedVisibleWidth, height);
    }, [dataPoints, samplingFrequency, zoomLevel, panOffset]);

    useEffect(() => {
        const quicklookCanvas = quicklookCanvasRef.current;
        const quicklookCtx = quicklookCanvas.getContext('2d');
        drawQuicklook(quicklookCtx, quicklookCanvas.width, quicklookCanvas.height);
    }, [drawQuicklook, samplingFrequency, zoomLevel, panOffset]);


    return (
        <canvas
            ref={quicklookCanvasRef}
            width={200}
            height={100}
            style={{
                border: '1px solid #ccc',
                marginBottom: '16px',
            }}
        />
    );
};
export default QuickLook;