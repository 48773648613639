const drawGrid = (ctx, width, height, zoomLevel, samplingFrequency) => {
    // Paramètres de la grille
    const mmPerSecond = 25; // 25 mm par seconde
    const mmPerMV = 10; // 10 mm par mV (standard pour l'amplitude)

    // Grille horizontale (temps)
    const secondsPerMillimeter = 1 / mmPerSecond; // Chaque millimètre représente 0.04s
    const pixelsPerMillimeterX = (samplingFrequency * secondsPerMillimeter * width) / (samplingFrequency * 5);
    const majorGridSizeX = 5 * pixelsPerMillimeterX * zoomLevel; // 5 mm (chaque grand carré représente 0.2s)
    const minorGridSizeX = pixelsPerMillimeterX * zoomLevel;     // 1 mm (0.04s)

    // Grille verticale (amplitude) - Fixer à 10 mm par mV pour une plage de -2 mV à 2 mV
    const yMin = -2; // -2 mV
    const yMax = 2;  // 2 mV
    const totalMillimetersY = (yMax - yMin) * mmPerMV; // Total de millimètres nécessaires en hauteur
    const pixelsPerMillimeterY = height / totalMillimetersY;

    // Espacement des lignes principales et secondaires sur l'axe Y
    const majorGridSizeY = 10 * pixelsPerMillimeterY; // Grands carrés : 10 mm pour 1 mV
    const minorGridSizeY = pixelsPerMillimeterY;      // Petits carrés : 1 mm pour 0.1 mV

    // Dessiner les lignes du quadrillage
    ctx.clearRect(0, 0, width, height);

    // Dessiner les grandes lignes du quadrillage (horizontal et vertical)
    ctx.strokeStyle = 'rgba(255, 165, 0, 0.8)';  // Orange pour les grands carrés (lignes majeures)
    ctx.lineWidth = 1;
    for (let x = 0; x <= width; x += majorGridSizeX) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();
    }
    for (let y = 0; y <= height; y += majorGridSizeY) {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
        ctx.stroke();
    }

    // Dessiner les petites lignes du quadrillage (horizontal et vertical)
    ctx.strokeStyle = 'rgba(255, 182, 107, 0.5)';  // Orange plus clair pour les petits carrés (lignes mineures)
    ctx.lineWidth = 0.5;
    for (let x = 0, countX = 0; x <= width; x += minorGridSizeX, countX++) {
        ctx.strokeStyle = (countX % 5 === 0) ? 'rgba(255, 165, 0, 0.8)' : 'rgba(255, 182, 107, 0.5)';
        ctx.lineWidth = (countX % 5 === 0) ? 1 : 0.5;
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();
    }
    for (let y = 0, countY = 0; y <= height; y += minorGridSizeY, countY++) {
        ctx.strokeStyle = (countY % 5 === 0) ? 'rgba(255, 165, 0, 0.8)' : 'rgba(255, 182, 107, 0.5)';
        ctx.lineWidth = (countY % 5 === 0) ? 1 : 0.5;
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
        ctx.stroke();
    }

    // Ajouter les labels de l'axe Y (en mV) pour une plage de -2 mV à 2 mV
    ctx.fillStyle = 'black';
    ctx.font = '12px Arial';
    for (let y = 0; y <= height; y += majorGridSizeY) {
        const voltageLabel = (yMax - (y / height) * (yMax - yMin)).toFixed(1);
        ctx.fillText(`${voltageLabel} mV`, 2, y - 2);
    }
};

export default drawGrid;