// PatientForm.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    InputAdornment,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    Paper,
    Button,
} from '@mui/material';

import { putPatients } from '../api/Patient';
import { fetchPatients } from '../api/Patient';
import { postPatients } from '../api/Patient';

import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

import PrescriptionForm from './PrescriptionForm';
import ArrythmiaForm from './ArrythmiaForm';
import ComplementaryExamsForm from './ComplementaryExamsForm';



const PatientForm = ({ formData, setFormData, setPatients, setShowForm }) => {
    const { t } = useTranslation();


    // Handlers pour la mise à jour du formulaire
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSymptomChange = (e) => {
        const { value, checked } = e.target;

        let updatedSymptoms;

        // Si "Aucun" est coché, on désélectionne tout le reste
        if (value === t('patients.symptomList.none') && checked) {
            updatedSymptoms = [t('patients.symptomList.none')];
        }
        // Si un autre symptôme est coché alors que "Aucun" est sélectionné, on retire "Aucun"
        else if (value !== t('patients.symptomList.none') && checked) {
            updatedSymptoms = [...formData.symptoms.filter(symptom => symptom !== t('patients.symptomList.none')), value];
        }
        // Si un autre symptôme est décoché
        else {
            updatedSymptoms = formData.symptoms.filter((symptom) => symptom !== value);
        }

        // Mettre à jour le formData avec la nouvelle liste de symptômes
        setFormData({ ...formData, symptoms: updatedSymptoms });
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = { ...formData };

        const isEditMode = !!data.patientCode; // Si le patient a un patientCode, c'est une mise à jour

        if (isEditMode) {
            // Effectuer un PUT pour mettre à jour le patient existant

            putPatients(data)
                .then((response) => {
                    if (response.status === 200) {
                        // Mettre à jour la liste des patients après la mise à jour
                        fetchPatients()
                            .then((response) => {
                                setPatients(response.data); // Mettre à jour la liste des patients
                            })
                            .catch(() => {
                                alert('Failed to refresh patients list. Please try again later.');
                            });
                        setShowForm(false); // Fermer le formulaire
                    }
                })
                .catch(() => {
                    alert('Error updating patient.');
                });
        } else {
            // Effectuer un POST pour créer un nouveau patient
            postPatients(data)
                .then((response) => {
                    if (response.status === 201) {
                        // Mettre à jour la liste des patients après la création
                        fetchPatients()
                            .then((response) => {
                                setPatients(response.data); // Mettre à jour la liste des patients
                            })
                            .catch(() => {
                                alert('Failed to refresh patients list. Please try again later.');
                            });
                        setShowForm(false); // Fermer le formulaire
                    }
                })
                .catch(() => {
                    alert('Error creating patient.');
                });
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, padding: 3 }}>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper elevation={3} sx={{ borderRadius: 2, padding: 3 }}>
                    {/* Titre de la boîte avec icône */}
                    <Box display="flex" alignItems="center" mb={2}>
                        <InfoIcon sx={{ color: '#1976d2', fontSize: 30, marginRight: 1 }} />
                        <Typography variant="h6" component="div">
                            {t('patients.general_information')}
                        </Typography>
                    </Box>

                    <Divider sx={{ mb: 2 }} />

                    {/* Formulaire */}
                    <Box display="flex" justifyContent="space-between" gap="16px">
                        {/* Date de naissance */}
                        <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                            <TextField
                                label={t('patients.dateOfBirth')}
                                type="date"
                                name="dateOfBirth"
                                value={formData.dateOfBirth ? formData.dateOfBirth : ''}
                                onChange={handleInputChange}
                                variant="outlined"
                                slotProps={{
                                    inputLabel: { shrink: true },
                                }}
                            />
                        </FormControl>

                        {/* Sexe */}
                        <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                            <InputLabel>{t('patients.sex')}</InputLabel>
                            <Select
                                label={t('patients.sex')}
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PersonIcon sx={{ color: '#ff4081' }} /> {/* Icône colorée pour égayer le champ */}
                                    </InputAdornment>
                                }
                            >
                                <MenuItem value="male">{t('patients.male')}</MenuItem>
                                <MenuItem value="female">{t('patients.female')}</MenuItem>
                                <MenuItem value="other">{t('patients.other')}</MenuItem>
                                <MenuItem value="prefer_not_to_say">{t('patients.prefer_not_to_say')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Paper>

                <PrescriptionForm
                    formData={formData}  // Transfert de l'état formData en tant que prop
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}  // Passer la fonction de gestion des changements
                />

                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                    <Paper elevation={3} sx={{ borderRadius: 2, padding: 3, width: '100%' }}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <LocalHospitalIcon sx={{ color: '#ff5722', fontSize: 30, marginRight: 1 }} />
                            <Typography variant="h6" component="div">
                                {t('patients.symptoms')}
                            </Typography>
                        </Box>
                        <Divider sx={{ mb: 2 }} />

                        <Typography variant="body1" sx={{ mb: 2 }}>
                            {t('patients.symptomsQuestion')}
                        </Typography>
                        <FormControl component="fieldset" sx={{ width: '100%' }}>
                            <FormGroup>
                                <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2}>
                                    {['none', 'fatigue', 'chestPain', 'dyspnea', 'palpitations', 'syncope', 'other'].map((symptomKey, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    value={t(`patients.symptomList.${symptomKey}`)}
                                                    onChange={handleSymptomChange}
                                                    checked={formData.symptoms.includes(t(`patients.symptomList.${symptomKey}`))}
                                                    disabled={
                                                        formData.symptoms.includes(t('patients.symptomList.none')) &&
                                                        symptomKey !== 'none'
                                                    }
                                                />
                                            }
                                            label={t(`patients.symptomList.${symptomKey}`)}
                                            sx={{ ml: 2 }} // Décalage pour que chaque case commence à gauche de sa colonne
                                        />
                                    ))}
                                </Box>
                            </FormGroup>
                        </FormControl>
                    </Paper>
                </Box>

                <ArrythmiaForm
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                />
                <ComplementaryExamsForm
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                />
            </form>
            <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                sx={{ marginTop: 3, alignSelf: 'flex-end', marginBottom: 2 }}
            >
                {t('patients.save')}
            </Button>
        </Box>
    );
};

export default PatientForm;
