import React from 'react';
import { Box, Paper, FormControl, FormGroup, FormControlLabel, Checkbox, Typography, TextField, Divider, MenuItem } from '@mui/material';
import MedicationIcon from '@mui/icons-material/Medication';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useTranslation } from 'react-i18next';

const PrescriptionForm = ({ formData, setFormData }) => {
  const { t } = useTranslation();

  const handlePrescriptionInputChange = (e) => {
    const { name, value } = e.target;
    const [prescriptionKey, field] = name.split('_');
  
    setFormData((prevState) => ({
      ...prevState,
      prescriptionDrugs: {
        ...prevState.prescriptionDrugs,
        [prescriptionKey]: {
          ...prevState.prescriptionDrugs[prescriptionKey],
          [field]: value,
        },
      },
    }));
  };
  
  const handleArchivedInputChange = (e) => {
    const { name, value } = e.target;
    console.log('handleArchivedInputChange called with:', name, value);
    const [prescriptionKey, field] = name.split('_');
    console.log(prescriptionKey)
    console.log(field)

  
    setFormData((prevState) => ({
      ...prevState,
      archivedDrugs: {
        ...prevState.archivedDrugs,
        [prescriptionKey]: {
          ...prevState.archivedDrugs[prescriptionKey],
          [field]: value,
        },
      },
    }));

  };
  

  // Gestion de la sélection des prescriptions en cours
  const handlePrescriptionChange = (e) => {
    const { value, checked } = e.target;
    let updatedPrescriptions = { ...formData.prescriptionDrugs };

    // Si "Aucun" est coché, on désélectionne tout le reste
    if (value === t('patients.prescriptionList.none') && checked) {
      updatedPrescriptions = { [t('patients.prescriptionList.none')]: { medicineName: '', dosage: '', startDate: '' } };
    } else if (value !== t('patients.prescriptionList.none') && checked) {
      delete updatedPrescriptions[t('patients.prescriptionList.none')];
      updatedPrescriptions[value] = { medicineName: '', dosage: '', startDate: '' };
    } else {
      delete updatedPrescriptions[value];
    }

    setFormData({ ...formData, prescriptionDrugs: updatedPrescriptions });
  };

  // Gestion de la sélection des prescriptions archivées
  const handleArchivedPrescriptionChange = (e) => {
    const { value, checked } = e.target;
    let updatedArchivedPrescriptions = { ...formData.archivedDrugs };

    // Si "Aucun" est coché, on désélectionne tout le reste
    if (value === t('patients.prescriptionList.none') && checked) {
      updatedArchivedPrescriptions = { [t('patients.prescriptionList.none')]: { medicineName: '', dosage: '', startDate: '', endDate: '' } };
    } else if (value !== t('patients.prescriptionList.none') && checked) {
      delete updatedArchivedPrescriptions[t('patients.prescriptionList.none')];
      updatedArchivedPrescriptions[value] = { medicineName: '', dosage: '', startDate: '', endDate: '' };
    } else {
      delete updatedArchivedPrescriptions[value];
    }

    setFormData({ ...formData, archivedDrugs: updatedArchivedPrescriptions });
  };

  const handleSelectChange = (e, prescriptionKey) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      prescriptionDrugs: {
        ...prevState.prescriptionDrugs,
        [prescriptionKey]: {
          ...prevState.prescriptionDrugs[prescriptionKey],
          medicineName: value,
          otherMedicine: value === 'Autre' ? '' : undefined,
        },
      },
    }));
  };

  const handleArchivedSelectChange = (e, prescriptionKey) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      archivedDrugs: {
        ...prevState.archivedDrugs,
        [prescriptionKey]: {
          ...prevState.archivedDrugs[prescriptionKey],
          medicineName: value,
          otherMedicine: value === 'Autre' ? '' : undefined,
        },
      },
    }));
  };

  return (
    <Box sx={{ display: 'flex', gap: 4, width: '100%', mb: 4, mt: 4 }}>
      {/* Bloc - Prescriptions en cours */}
      <Paper elevation={3} sx={{ flex: 1, borderRadius: 2, padding: 3 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <MedicationIcon sx={{ color: '#4caf50', fontSize: 28, marginRight: 1 }} />
          <Typography variant="h6">{t('patients.current_prescription')}</Typography>
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* Liste des prescriptions en cours */}
        <FormControl component="fieldset">
          <FormGroup>
            {['antiarrhythmics', 'calciumChannelBlockers', 'betaBlockers', 'sodiumChannelBlockers', 'other'].map((prescriptionKey, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                {/* Checkbox pour chaque médicament en cours */}
                <FormControlLabel
                  control={
                    <Checkbox
                      value={prescriptionKey}
                      onChange={handlePrescriptionChange}
                      checked={formData.prescriptionDrugs && formData.prescriptionDrugs[prescriptionKey] !== undefined}
                    />
                  }
                  label={t(`patients.prescriptionList.${prescriptionKey}`)}
                />
                {/* Détails si le médicament est coché */}
                {formData.prescriptionDrugs && formData.prescriptionDrugs[prescriptionKey] && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4, mt: 1, gap: 2 }}>
                    <TextField
                      select
                      label={t('patients.medicineName')}
                      name={`${prescriptionKey}_medicine`}
                      value={formData.prescriptionDrugs[prescriptionKey].medicineName || ''}
                      onChange={(e) => handleSelectChange(e, prescriptionKey)}
                      variant="outlined"
                      sx={{ mb: 1, width: '100%' }}
                    >
                      {(t(`patients.medicineOptions.${prescriptionKey}`, { returnObjects: true }) || []).map((option, i) => (
                        <MenuItem key={i} value={option}>{option}</MenuItem>
                      ))}
                    </TextField>
                    {formData.prescriptionDrugs[prescriptionKey].medicineName === 'Autre' && (
                      <TextField
                        label={t('patients.otherMedicineName')}
                        name={`${prescriptionKey}_otherMedicine`}
                        value={formData.prescriptionDrugs[prescriptionKey].otherMedicine || ''}
                        onChange={handlePrescriptionInputChange}
                        variant="outlined"
                        sx={{ mb: 1, width: '100%' }}
                      />
                    )}
                    <TextField
                      label={t('patients.dosage')}
                      name={`${prescriptionKey}_dosage`}
                      value={formData.prescriptionDrugs[prescriptionKey].dosage || ''}
                      onChange={handlePrescriptionInputChange}
                      variant="outlined"
                      sx={{ mb: 1, width: '100%' }}
                    />
                    <TextField
                      label={t('patients.startDate')}
                      type="date"
                      name={`${prescriptionKey}_startDate`}
                      value={formData.prescriptionDrugs[prescriptionKey].startDate || ''}
                      onChange={handlePrescriptionInputChange}
                      variant="outlined"
                      slotProps={{
                        inputLabel: { shrink: true },
                    }}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                )}
              </Box>
            ))}
          </FormGroup>
        </FormControl>
      </Paper>

      {/* Bloc - Prescriptions archivées */}
      <Paper elevation={3} sx={{ flex: 1, borderRadius: 2, padding: 3 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <ArchiveIcon sx={{ color: '#1976d2', fontSize: 28, marginRight: 1 }} />
          <Typography variant="h6">{t('patients.archived_prescriptions')}</Typography>
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* Liste des prescriptions archivées */}
        <FormControl component="fieldset">
          <FormGroup>
            {['antiarrhythmics', 'calciumChannelBlockers', 'betaBlockers', 'sodiumChannelBlockers', 'other'].map((prescriptionKey, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                {/* Checkbox pour chaque médicament archivé */}
                <FormControlLabel
                  control={
                    <Checkbox
                      value={prescriptionKey}
                      onChange={handleArchivedPrescriptionChange}
                      checked={formData.archivedDrugs && formData.archivedDrugs[prescriptionKey] !== undefined}
                    />
                  }
                  label={t(`patients.prescriptionList.${prescriptionKey}`)}
                />
                {/* Détails si le médicament archivé est coché */}
                {formData.archivedDrugs && formData.archivedDrugs[prescriptionKey] && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4, mt: 1, gap: 2 }}>
                    <TextField
                      select
                      label={t('patients.medicineName')}
                      name={`${prescriptionKey}_medicine`}
                      value={formData.archivedDrugs[prescriptionKey].medicineName || ''}
                      onChange={(e) => handleArchivedSelectChange(e, prescriptionKey)}
                      variant="outlined"
                      sx={{ mb: 1, width: '100%' }}
                    >
                      {(t(`patients.medicineOptions.${prescriptionKey}`, { returnObjects: true }) || []).map((option, i) => (
                        <MenuItem key={i} value={option}>{option}</MenuItem>
                      ))}
                    </TextField>
                    {formData.archivedDrugs[prescriptionKey].medicineName === 'Autre' && (
                      <TextField
                        label={t('patients.otherMedicineName')}
                        name={`${prescriptionKey}_otherMedicine`}
                        value={formData.archivedDrugs[prescriptionKey].otherMedicine || ''}
                        onChange={handleArchivedInputChange}
                        variant="outlined"
                        sx={{ mb: 1, width: '100%' }}
                      />
                    )}
                    <TextField
                      label={t('patients.dosage')}
                      name={`${prescriptionKey}_dosage`}
                      value={formData.archivedDrugs[prescriptionKey].dosage || ''}
                      onChange={handleArchivedInputChange}
                      variant="outlined"
                      sx={{ mb: 1, width: '100%' }}
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <TextField
                        label={t('patients.startDate')}
                        type="date"
                        name={`${prescriptionKey}_startDate`}
                        value={formData.archivedDrugs[prescriptionKey].startDate || ''}
                        onChange={handleArchivedInputChange}
                        variant="outlined"
                        slotProps={{
                            inputLabel: { shrink: true },
                        }}
                        sx={{ width: '50%' }}
                      />
                      <TextField
                        label={t('patients.endDate')}
                        type="date"
                        name={`${prescriptionKey}_endDate`}
                        value={formData.archivedDrugs[prescriptionKey].endDate || ''}
                        onChange={handleArchivedInputChange}
                        variant="outlined"
                        slotProps={{
                            inputLabel: { shrink: true },
                        }}
                        sx={{ width: '50%' }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          </FormGroup>
        </FormControl>
      </Paper>
    </Box>
  );
};

export default PrescriptionForm;