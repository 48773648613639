import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from '../UserPool';
import { TextField, Button, Box, InputAdornment, Snackbar, Alert } from '@mui/material';
import { Email, Lock, VpnKey } from '@mui/icons-material'; // Importation des icônes
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';

const ForgotPasswordForm = ({ setIsForgotPassword, setError }) => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);  // Ouvre la popup pour le code envoyé
    const [openPasswordSnackbar, setOpenPasswordSnackbar] = useState(false); // Ouvre la popup pour succès/échec mise à jour du mot de passe
    const [passwordUpdateStatus, setPasswordUpdateStatus] = useState(''); // Statut pour succès/échec

    // Vérifie que les mots de passe correspondent
    const isPasswordValid = () => newPassword === confirmPassword;

    // Fonction pour valider l'adresse email
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  // Regex simple pour valider les emails
        return emailRegex.test(email);
    };

    const handleConfirmPasswordSubmit = (event) => {
        event.preventDefault();
        if (!isPasswordValid()) {
            setError(t('login.passwordsDoNotMatch'));
            return;
        }

        const user = new CognitoUser({ Username: email, Pool: UserPool });

        user.confirmPassword(verificationCode, newPassword, {
            onSuccess: () => {
                setPasswordUpdateStatus('success');
                setOpenPasswordSnackbar(true);  // Affiche la popup pour succès
                setTimeout(() => {
                    setIsForgotPassword(false);  // Redirection après 3 secondes
                }, 3000);  // Temps d'attente pour laisser le message s'afficher
            },
            onFailure: (err) => {
                setError(err.message || JSON.stringify(err));
                setPasswordUpdateStatus('failure');
                setOpenPasswordSnackbar(true);  // Affiche la popup pour échec
            },
        });
    };

    const handleForgotPasswordSubmit = (event) => {
        event.preventDefault();

        // Valider l'email avant d'envoyer la demande de code de vérification
        if (!validateEmail(email)) {
            setEmailError(t('login.invalidEmail'));
            return;
        }

        setEmailError('');  // Réinitialise l'erreur si l'email est valide
        const user = new CognitoUser({ Username: email, Pool: UserPool });

        user.forgotPassword({
            onSuccess: (data) => {
                console.log('Code de vérification envoyé', data);
                setOpenSnackbar(true);  // Ouvre la popup pour le code envoyé
            },
            onFailure: (err) => {
                setError(err.message || JSON.stringify(err));
            },
        });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
        setOpenPasswordSnackbar(false);
    };

    return (
        <Box sx={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}>
            <Box component="form" onSubmit={handleForgotPasswordSubmit} sx={{ mt: 1 }}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('login.email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    slotProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Email color="primary" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}
                    error={!!emailError}
                    helperText={emailError || ''}
                />
                <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2, borderRadius: '16px' }}>
                    {t('login.sendVerificationCode')}
                </Button>
            </Box>
            <Box>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('login.verificationCode')}
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    slotProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <VpnKey color="primary" />  
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        mt: 2,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('login.newPassword')}
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    slotProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock color="primary" />  
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        mt: 2,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('login.confirmPassword')}
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    slotProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock color="primary" />  
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        mt: 2,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}                />
                <Button type="button" fullWidth variant="contained" color="success" sx={{ mt: 3, mb: 2, borderRadius: '16px' }} onClick={handleConfirmPasswordSubmit}>
                    {t('login.confirmPassword')}
                </Button>
                <Button variant="text" onClick={() => setIsForgotPassword(false)} sx={{ mt: 2, color: 'primary.main' }}>
                    {t('login.backToLogin')}
                </Button>
            </Box>

            {/* Snackbar pour afficher la notification de code envoyé */}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {t('login.verificationCodeSent')}
                </Alert>
            </Snackbar>

            {/* Snackbar pour succès/échec mise à jour du mot de passe */}
            <Snackbar open={openPasswordSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                {passwordUpdateStatus === 'success' ? (
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        {t('login.passwordUpdated')}
                    </Alert>
                ) : (
                    <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        {t('login.passwordUpdateFailed')}
                    </Alert>
                )}
            </Snackbar>
        </Box>
    );
};

export default ForgotPasswordForm;
