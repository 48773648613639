import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Container,
    CircularProgress,
    Divider,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchECGDetails, fetchPatientECGs } from '../api/ECG';
import { fetchPatients } from '../api/Patient';

import PatientSelector from '../components/PatientSelector';
import ECGDetails from '../components/ECGDetails';
import ECGDisplay from '../components/ECGDisplay';
import ECGList from '../components/ECGList';
import RRIntervalChart from '../components/RRIntervalChart';
import PatientDetails from '../components/PatientDetails';
import RecentViewedECGs from '../components/RecentViewedECGs';

const EcgDetailPage = () => {
    const { t } = useTranslation();
    const { patientCode, ecgId } = useParams();
    const [patients, setPatients] = useState([]);
    const [patient, setPatient] = useState(null);
    const [ecg, setEcg] = useState(null);
    const [ecgList, setEcgList] = useState([]);
    const [patientECGList, setPatientECGList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [recentViewedECGs, setRecentViewedECGs] = useState([]);

    const rrIntervals = [];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const patientsResponse = await fetchPatients();
                if (patientsResponse && patientsResponse.data) {
                    const { patients, recentViewedECGs } = patientsResponse.data;
                    if (Array.isArray(patients)) {
                        setPatients(patients);
                        setRecentViewedECGs(recentViewedECGs || []);
                    } else {
                        console.error("Unexpected patient data format:", patientsResponse.data);
                    }
                } else {
                    console.error("Unexpected response format:", patientsResponse);
                }

                const ecgResponse = await fetchPatientECGs({ all: true });
                if (ecgResponse && ecgResponse.data) {
                    setEcgList(ecgResponse.data);
                    if (patientCode) {
                        const selectedPatient = patientsResponse.data?.patients.find((p) => p.patientCode === patientCode);
                        if (selectedPatient) {
                            setPatient(selectedPatient);
                            setPatientECGList(ecgResponse.data.filter((e) => e.patientCode === patientCode));
                            if (ecgId) {
                                const selectedECG = ecgResponse.data.find((e) => e.id === ecgId);
                                if (selectedECG) {
                                    const ecgDetailsResponse = await fetchECGDetails(selectedPatient, ecgId);
                                    setEcg(ecgDetailsResponse.data);
                                }
                            }
                        } else {
                            console.error("Patient not found for patientCode:", patientCode);
                        }
                    }
                } else {
                    console.error("Unexpected ECG data format:", ecgResponse);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                alert('Failed to fetch data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [patientCode, ecgId]);

    const handlePatientChange = (event) => {
        const selected = patients.find((p) => p.patientCode === event.target.value);
        setPatient(selected);
        if (selected) {
            const filteredECGs = ecgList.filter((e) => e.patientCode === selected.patientCode);
            setPatientECGList(filteredECGs);
        }
    };

    const handleECGSelect = (ecg) => {
        setLoading(true);
        fetchECGDetails(patient, ecg.id)
            .then((response) => {
                setEcg(response.data);
            })
            .catch((error) => {
                console.error("Failed to fetch ECG details:", error);
                alert('Failed to fetch ECGs. Please try again later.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    for (let i = 1; i < ecg?.rPeaks?.length; i++) {
        const interval = (ecg.rPeaks[i].time - ecg.rPeaks[i - 1].time).toFixed(4);
        rrIntervals.push(parseFloat(interval));
    }

    return (
        <Container maxWidth="false" style={{ marginTop: '10px', display: 'flex', marginLeft: '2%', marginRight: '2%' }}>
            <Box sx={{ width: '27%', flexShrink: 0, mr: '2%' }}>
                <Box p={2} role="presentation" sx={{ borderRight: '1px solid #e0e0e0' }}>
                    <PatientSelector
                        patients={patients}
                        selectedPatient={patient?.patientCode || ''}
                        handlePatientChange={handlePatientChange}
                    />
                    <Divider sx={{ my: 2 }} />
                    <ECGList
                        ecgList={patientECGList}
                        handleECGSelect={handleECGSelect}
                        patient={patient}
                    />
                </Box>
            </Box>
            <Box sx={{ width: '67%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {patient ? (
                    <Box sx={{ flexShrink: 0 }}>
                        <PatientDetails patient={patient} />
                    </Box>
                ) : (
                    <>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h6">{t('ecg.noPatient')}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <RecentViewedECGs recentViewedECGs={recentViewedECGs} ecgList={ecgList} />
                        </Box></>
                )}
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                        <CircularProgress />
                    </Box>
                ) : (patient && !ecg ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                        <Typography variant="h6">{t('ecg.noECGSelected')}</Typography>
                    </Box>
                ) : (
                    ecg && (
                        <>
                            <Box
                                sx={{ flexShrink: 0 }}
                            >
                                <ECGDetails ecg={ecg} patient={patient} />
                            </Box>
                            <Box
                                sx={{ flexShrink: 0 }}
                            >
                                <ECGDisplay ecg={ecg} />
                            </Box>

                            <Box
                                sx={{ flexShrink: 0 }}
                            >
                                <RRIntervalChart rrIntervals={rrIntervals} t={t} />
                            </Box>
                        </>
                    )))}
            </Box>
        </Container>
    );
};

export default EcgDetailPage;
