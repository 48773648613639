import Cookies from 'js-cookie';
import axios from 'axios';

const API_ECG_URL = 'https://api.emcardia.com/prod/ecg';
const API_KEY = 'QZtBbIjzTQ7zVc3gKQxYE9EOy3Z99PWE10lYtgY5';

export const fetchECGDetails = (patient, ecgId) => {
    const token = Cookies.get('jwt');
    return axios.get(`${API_ECG_URL}/${ecgId}?patientCode=${patient.patientCode}&accessCode=${patient.accessCode}`, {
        headers: {
            'x-api-key': API_KEY,
            'Authorization': `Bearer ${token}`,
        },
    });
};

export const fetchPatientECGs = (patient = {}) => {
    const token = Cookies.get('jwt');
    let url = `${API_ECG_URL}`;

    if (patient.patientCode && patient.accessCode) {
        url += `?patientCode=${patient.patientCode}&accessCode=${patient.accessCode}`;
    }

    return axios.get(url, {
        headers: {
            'x-api-key': API_KEY,
            'Authorization': `Bearer ${token}`,
        },
    });
};

