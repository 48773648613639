import React, { useState } from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

const ECGList = ({ ecgList, handleECGSelect, patient }) => {
    const { t } = useTranslation();
    const [order, setOrder] = useState('asc'); // Default sort order
    const [orderBy, setOrderBy] = useState('startDate'); // Default sort column
    const [selectedECG, setSelectedECG] = useState(null); // Selected ECG state
    const [statusFilter, setStatusFilter] = useState([]);

    // Formatting functions
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        return date.toISOString().slice(0, 19).replace('T', ' ');
    };

    const formatHeartRate = (heartRate) => `${Math.round(heartRate)} BPM`;

    // Sort handler
    const handleSort = (property) => {
        const isAscending = orderBy === property && order === 'asc';
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Handle row click
    const handleRowClick = (ecg) => {
        setSelectedECG(ecg);
        handleECGSelect(ecg);
    };

    const handleStatusChange = (event) => {
        const value = event.target.value;
        if (value.includes('all')) {
            if (statusFilter.length === statusOptions.length) {
                setStatusFilter([]);
            } else {
                setStatusFilter(statusOptions.map(([key]) => key));
            }
        } else {
            setStatusFilter(value);
        }
    };

    const statusOptions = Object.entries(t('ecg.status', { returnObjects: true }));

    // Filtering and sorting logic
    const filteredECGList = Array.isArray(ecgList)
        ? ecgList.filter((ecg) =>
            statusFilter.length === 0 || statusFilter.includes(ecg.ECGStatus)
        )
        : [];

    const sortedECGList = filteredECGList.sort((a, b) => {
        if (order === 'asc') {
            return new Date(a[orderBy]) - new Date(b[orderBy]);
        }
        return new Date(b[orderBy]) - new Date(a[orderBy]);
    });

    return (
        <Box sx={{ width: '100%', backgroundColor: 'transparent' }}>
            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                {t('ecg.ecgList')}
            </Typography>
            {patient && Object.keys(patient).length > 0 && (
                <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
                    <InputLabel id="filter-status-label">{t('ecg.filter_status')}</InputLabel>
                    <Select
                        labelId="filter-status-label"
                        multiple
                        value={statusFilter}
                        onChange={handleStatusChange}
                        label={t('ecg.filter_status')}
                        renderValue={(selected) => selected.length === statusOptions.length ? t('ecg.all_status') : selected.map((key) => t(`ecg.status.${key}`)).join(', ')}
                    >
                        <MenuItem value="all">
                            <Checkbox checked={statusFilter.length === statusOptions.length} />
                            <ListItemText primary={t('ecg.select_all')} />
                        </MenuItem>
                        {statusOptions.map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                <Checkbox checked={statusFilter.includes(key)} />
                                <ListItemText primary={value} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <Typography variant="body2" sx={{ mb: 2, textAlign: 'center', color: 'text.secondary' }}>
                {t('ecg.clickToSelect')}
            </Typography>
            <TableContainer sx={{ width: '100%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'startDate'}
                                    direction={orderBy === 'startDate' ? order : 'asc'}
                                    onClick={() => handleSort('startDate')}
                                >
                                    {t('ecg.startDate')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                {t('ecg.heartRate')}
                            </TableCell>
                            <TableCell>
                                {t('ecg.status_field')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedECGList.map((ecg, index) => (
                            <TableRow
                                key={index}
                                hover
                                selected={selectedECG === ecg}
                                onClick={() => handleRowClick(ecg)}
                                sx={{ cursor: 'pointer', transition: 'background-color 0.3s', '&:hover': { backgroundColor: '#f0f0f0' } }}
                            >
                                <TableCell>{formatDate(ecg.startDate)}</TableCell>
                                <TableCell>{formatHeartRate(ecg.heartRate)}</TableCell>
                                <TableCell>{t('ecg.status.' + ecg.ECGStatus)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ECGList;