import React from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Avatar,
    Stack,
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import CakeIcon from '@mui/icons-material/Cake';
import WcIcon from '@mui/icons-material/Wc';

import { useTranslation } from 'react-i18next';

const PatientDetails = ({ patient = {} }) => {
    const { t } = useTranslation();

    const calculateAge = (dateOfBirth) => {
        if (!dateOfBirth) return '-';
        const dob = new Date(dateOfBirth);
        const diff = Date.now() - dob.getTime();
        const ageDate = new Date(diff);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    return (
        <>
            <Typography variant="h5" sx={{ mb: 3 }}>{t('patients.patientDetails')}</Typography>
            <PatientBasicInfo patient={patient} calculateAge={calculateAge} t={t} />
        </>
    );
};

const PatientBasicInfo = ({ patient, calculateAge, t }) => (
    <Stack direction="row" spacing={2} flexWrap="wrap" sx={{ mb: 4 }}>
        <InfoCard
            icon={<PersonIcon />}
            avatarColor="#007FFF"
            title={t('patients.patientCode')}
            content={patient.patientCode || '-'}
        />
        <InfoCard
            icon={<CakeIcon />}
            avatarColor="#FF5722"
            title={t('patients.age')}
            content={calculateAge(patient.dateOfBirth)}
        />
        <InfoCard
            icon={<WcIcon />}
            avatarColor="#4CAF50"
            title={t('patients.sex')}
            content={patient.gender ? t(`patients.${patient.gender}`) : '-'}
        />
    </Stack>
);

const InfoCard = ({ icon, avatarColor, title, content }) => (
    <Card sx={{
        minWidth: 200,
        flex: '1 1 calc(33% - 16px)',
        marginBottom: 2,
        borderRadius: 4,
        backgroundColor: 'transparent'
    }}>
        <CardContent>
            <Stack direction="row" spacing={2} alignItems="center">
                <Avatar sx={{ bgcolor: avatarColor }}>
                    {icon}
                </Avatar>
                <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                        {title}
                    </Typography>
                    <Typography variant="body1">{content}</Typography>
                </Box>
            </Stack>
        </CardContent>
    </Card>
);

export default PatientDetails;
