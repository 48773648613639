import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import './i18n'; // Ajout de la configuration i18n
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context'; // Permet d'ajouter des en-têtes dynamiques
import Cookies from 'js-cookie';

// Configuration du lien HTTP
const httpLink = new HttpLink({
  uri: 'https://h26l52ab3nea5fy34hbvuccdta.appsync-api.eu-west-3.amazonaws.com/graphql', // AppSync API URL
});

// Configuration pour injecter les en-têtes dynamiques
const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("jwt"); // Récupérer le token JWT depuis les cookies
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "", // Ajouter le token à l'en-tête Authorization
    },
  };
});

// Configuration d'Apollo Client
const client = new ApolloClient({
  link: authLink.concat(httpLink), // Combine authLink et httpLink
  cache: new InMemoryCache(),
});

// Configuration de l'application React
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

// Mesures de performance
reportWebVitals();
