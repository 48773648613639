import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress, Button, Alert } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CognitoUser } from 'amazon-cognito-identity-js';
import userPool from '../UserPool';
import { useTranslation } from 'react-i18next';

const VerifyEmail = () => {
  const { t } = useTranslation();  // Import useTranslation hook
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [errorMessage, setErrorMessage] = useState('');
  const [resendSuccess, setResendSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const email = queryParams.get('email');
    const code = queryParams.get('code');

    if (email && code) {
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      confirmUser(cognitoUser, code);
    } else {
      setVerificationStatus('failed');
      setErrorMessage(t('errorMessage.missingEmailOrCode'));
    }
  }, [location.search, t]);

  const confirmUser = (cognitoUser, code) => {
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        console.log(err.code)
        switch (err.code) {
          case 'LimitExceededException':
            setVerificationStatus('limitExceeded');
            setErrorMessage(t('errorMessage.limitExceeded'));
            break;
          case 'ExpiredCodeException':
          case 'CodeMismatchException':
            setVerificationStatus('expired');
            setErrorMessage(t('errorMessage.invalidOrExpiredCode'));
            break;
          case 'NotAuthorizedException':
            if (err.message.includes('already confirmed')) {
              setVerificationStatus('alreadyConfirmed');
              setErrorMessage(t('errorMessage.alreadyConfirmed'));
              setTimeout(() => {
                navigate('/login');
              }, 5000);
            }
            break;
          default:
            setVerificationStatus('failed');
            setErrorMessage(t('errorMessage.unexpectedError'));
        }
      } else {
        setVerificationStatus('success');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    });
  };

  const resendConfirmationCode = (email) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        setErrorMessage(t('errorMessage.resendLimit'));  
      } else {
        setResendSuccess(true);
        setErrorMessage('');
      }
    });
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: 8 }}>
      {verificationStatus === 'pending' && (
        <>
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            {t('verification.inProgress')}
          </Typography>
        </>
      )}

      {verificationStatus === 'success' && (
        <Box sx={{ textAlign: 'center' }}>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 80 }} />
          <Typography variant="h4" sx={{ marginTop: 2 }}>
            {t('verification.success')}
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            {t('verification.redirectToLogin')}
          </Typography>
          <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={() => navigate('/login')}>
            {t('verification.goToLogin')}
          </Button>
        </Box>
      )}

      {verificationStatus === 'alreadyConfirmed' && (
        <Box sx={{ textAlign: 'center' }}>
          <CheckCircleOutlineIcon color="info" sx={{ fontSize: 80 }} />
          <Typography variant="h4" sx={{ marginTop: 2 }}>
            {t('verification.alreadyConfirmed')}
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            {t('verification.redirectToLoginIn5')}
          </Typography>
          <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={() => navigate('/login')}>
            {t('verification.goToLogin')}
          </Button>
        </Box>
      )}

      {verificationStatus === 'limitExceeded' && (
        <Box sx={{ textAlign: 'center' }}>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 80 }} />
          <Typography variant="h4" sx={{ marginTop: 2, fontWeight: 'bold' }}>
            {t('verification.limitExceeded')}
          </Typography>
          <Alert severity="error" sx={{ marginTop: 2 }}>
            {errorMessage || t('verification.limitExceeded')}
          </Alert>
        </Box>
      )}

      {verificationStatus === 'expired' && (
        <Box sx={{ textAlign: 'center' }}>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 80 }} />
          <Typography variant="h4" sx={{ marginTop: 2, fontWeight: 'bold' }}>
            {t('verification.expired')}
          </Typography>
          <Alert severity="error" sx={{ marginTop: 2 }}>
            {errorMessage || t('verification.expired')}
          </Alert>
          <Button
            variant="contained"
            sx={{
              marginTop: 3,
              padding: '10px 20px',
              fontSize: '16px',
              fontWeight: 'bold',
              backgroundColor: '#007BFF',
              color: '#fff'
            }}
            onClick={() => {
              const queryParams = getQueryParams(location.search);
              const email = queryParams.get('email');
              if (email) {
                resendConfirmationCode(email);
              } else {
                setErrorMessage(t('errorMessage.missingEmailOrCode'));
              }
            }}
          >
            {t('verification.resendCode')}
          </Button>
          {resendSuccess && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              {t('verification.codeResent')}
            </Alert>
          )}
        </Box>
      )}

      {verificationStatus === 'failed' && (
        <Box sx={{ textAlign: 'center' }}>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 80 }} />
          <Typography variant="h4" sx={{ marginTop: 2, fontWeight: 'bold' }}>
            {t('verification.failed')}
          </Typography>
          <Alert severity="error" sx={{ marginTop: 2 }}>
            {errorMessage || t('errorMessage.unexpectedError')}
          </Alert>
          <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={() => navigate('/login')}>
            {t('verification.goToLogin')}
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default VerifyEmail;
