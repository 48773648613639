import React from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    Avatar,
    Stack,
    Divider,
    Container,
    Tooltip,
    IconButton
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WatchIcon from '@mui/icons-material/Watch';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BoltIcon from '@mui/icons-material/Bolt';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { useTranslation } from 'react-i18next';

const ECGDetails = ({ ecg = {}, patient = {} }) => {
    const { t } = useTranslation();

    const renderInfoIcon = (tooltipText) => (
        <Tooltip title={tooltipText} placement="top">
            <IconButton size="small">
                <InfoIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );

    const getHeartRateColor = (heartRate) => {
        if (heartRate < 100) return 'green';
        if (heartRate <= 180) return 'orange';
        return 'red';
    };

    const getStatusColor = (status) => {
        return ['ClinicalStatus.NORMAL_SINUS_RHYTHM', 'ClinicalStatus.SINUS_TACHYCARDIA'].includes(status) ? 'green' : 'orange';
    };

    return (
        <Accordion defaultExpanded={false} sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">{t('ecg.ecgDetails')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* First Line */}
                <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
                    {/* ECG Device and Sampling Frequency Card */}
                    <Card sx={{ flex: 1, borderRadius: 4, p: 1, boxShadow: 3, backgroundColor: 'transparent' }}>
                        <CardContent sx={{ p: 1 }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Avatar sx={{ bgcolor: '#007FFF', width: 40, height: 40 }}>
                                    <WatchIcon />
                                </Avatar>
                                <Box>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {t('ecg.device')}
                                    </Typography>
                                    <Typography variant="body1">
                                        {ecg.device || '-'}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>

                    {/* Heart Rate Card */}
                    <Card
                        sx={{
                            flex: 1,
                            borderRadius: 4,
                            p: 1,
                            boxShadow: 3,
                            marginBottom: 2,
                            backgroundColor: 'transparent',
                        }}
                    >
                        <CardContent
                            sx={{
                                p: 1,
                                display: 'flex',
                                alignItems: 'center', // Alignement vertical centré
                                justifyContent: 'flex-start', // Alignement horizontal à gauche
                            }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: getHeartRateColor(Math.round(ecg.heartRate)),
                                    width: 40,
                                    height: 40,
                                    mr: 2, // Espacement entre l'avatar et le texte
                                }}
                            >
                                <FavoriteIcon fontSize="small" />
                            </Avatar>
                            <Typography
                                variant="h5"
                                sx={{
                                    color: getHeartRateColor(Math.round(ecg.heartRate)),
                                }}
                            >
                                {ecg.heartRate ? `${Math.round(ecg.heartRate)} bpm` : '-'}
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* ECG Status Card */}
                    <Card
                        sx={{
                            flex: 1,
                            borderRadius: 4,
                            p: 1,
                            boxShadow: 3,
                            backgroundColor: 'transparent',
                        }}
                    >
                        <CardContent
                            sx={{
                                p: 1,
                                display: 'flex',
                                alignItems: 'center', // Alignement vertical centré
                                justifyContent: 'flex-start', // Alignement horizontal à gauche
                            }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: getStatusColor(ecg.ECGStatus),
                                    width: 40,
                                    height: 40,
                                    mr: 2, // Espacement entre l'avatar et le texte
                                }}
                            >
                                <BoltIcon fontSize="large" />
                            </Avatar>
                            <Typography
                                variant="h5"
                                sx={{
                                    color: getStatusColor(ecg.ECGStatus),
                                }}
                            >
                                {t('ecg.status.' + ecg.ECGStatus) || '-'}
                            </Typography>
                        </CardContent>
                    </Card>


                </Stack>

                {/* Second Line */}
                <Stack direction="row" spacing={2}>
                    {/* Basic Information Card */}
                    <Card sx={{ flex: 1, borderRadius: 4, p: 1.5, boxShadow: 3, backgroundColor: 'transparent' }}>
                        <CardContent sx={{ p: 1 }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Avatar sx={{ bgcolor: '#3f51b5', width: 40, height: 40 }}>
                                    <AssignmentIcon />
                                </Avatar>
                                <Typography variant="h6" gutterBottom>
                                    {t('ecg.basicInformation')}
                                </Typography>
                            </Stack>
                            <Divider sx={{ mb: 1.5, mt: 1 }} />
                            <Container maxWidth="lg">
                                <Stack spacing={1.5}>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {t('ecg.qt')}
                                        </Typography>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.QT ? `${Math.round(ecg.QT * 1000)} ms` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {`< 420 ms`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            QTc<sub>B</sub>
                                        </Typography>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.QTcB ? `${Math.round(ecg.QTcB * 1000)} ms` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {patient && patient.gender === 'female' ? '< 460 ms' : '< 440 ms'}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            QTc<sub>F</sub>
                                        </Typography>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.QTcF ? (
                                                    <span>
                                                        {`${Math.round(ecg.QTcF * 1000)} ms`}
                                                    </span>
                                                ) : (
                                                    '-'
                                                )}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {patient && patient.gender === 'female' ? '< 460 ms' : '< 440 ms'}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {t('ecg.PRInterval')}
                                        </Typography>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.PRInterval ? `${Math.round(ecg.PRInterval)} ms` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {`120 - 200 ms`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {t('ecg.QRSDuration')}
                                        </Typography>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.QRSDuration ? `${Math.round(ecg.QRSDuration)} ms` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {`< 120 ms`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {t('ecg.RRInterval')}
                                        </Typography>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.RRInterval ? `${Math.round(ecg.RRInterval)} ms` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {`Variable`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Container>
                        </CardContent>
                    </Card>

                    {/* Additional Information Card */}
                    <Card sx={{ flex: 1, borderRadius: 4, p: 1.5, boxShadow: 3, backgroundColor: 'transparent' }}>
                        <CardContent sx={{ p: 1 }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Avatar sx={{ bgcolor: '#f50057', width: 40, height: 40 }}>
                                    <AssessmentIcon />
                                </Avatar>
                                <Typography variant="h6" gutterBottom>
                                    {t('ecg.additionalInformation')}
                                </Typography>
                            </Stack>
                            <Divider sx={{ mb: 1.5, mt: 1 }} />
                            <Container maxWidth="lg">
                                <Stack spacing={1.5}>
                                    <Box>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {t('ecg.SDNN')}
                                            </Typography>
                                            {renderInfoIcon(t('ecg.tooltips.SDNN'))}

                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.SDNN ? `${Math.round(ecg.SDNN)} ms` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {`< 30 ms`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {t('ecg.CVRR')}
                                            </Typography>
                                            {renderInfoIcon(t('ecg.tooltips.CVRR'))}
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">

                                            <Typography variant="body1">
                                                {ecg.CVRR ? `${Math.round(ecg.CVRR * 100)} %` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {`< 20 %`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {t('ecg.PNN50')}
                                            </Typography>
                                            {renderInfoIcon(t('ecg.tooltips.PNN50'))}
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.PNN50 ? `${Math.round(ecg.PNN50)}` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {`< 5 %`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {t('ecg.FAIndex')}
                                            </Typography>
                                            {renderInfoIcon(t('ecg.tooltips.FAIndex'))}
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.FAIndex ? `${Math.round(ecg.FAIndex * 100) / 100}` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {`< 1`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {t('ecg.FAProbability')}
                                            </Typography>
                                            {renderInfoIcon(t('ecg.tooltips.FAProbability'))}
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="body1">
                                                {ecg.FAProbability ? `${Math.round(ecg.FAProbability)} %` : '-'}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                {`< 30%`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Container>
                        </CardContent>
                    </Card>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

export default ECGDetails;
