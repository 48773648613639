import React from 'react';
import {
    FormControlLabel,
    Checkbox,
} from '@mui/material';

import { useTranslation } from 'react-i18next';


const Filter = ({ showFilteredData, setShowFilteredData }) => {
    const { t } = useTranslation();

    return(
        <FormControlLabel
        control={
            <Checkbox
                checked={showFilteredData}
                onChange={(e) => setShowFilteredData(e.target.checked)}
                color="primary"
            />
        }
        label={t('ecg.display_filtered_version')}
    />
    );

};

export default Filter;
 