// components/RRIntervalsChart.js
import React, { useRef, useMemo, useEffect } from 'react';
import { Box, CardContent, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chart from 'chart.js/auto';

const RRIntervalsChart = ({ rrIntervals, t }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    // Generate labels for the chart
    const labels = useMemo(() => rrIntervals.map((_, index) => `${index + 1}`), [rrIntervals]);

    // Prepare the data for the chart
    const data = useMemo(() => ({
        labels: labels,
        datasets: [
            {
                label: 'R-R Intervals (ms)',
                data: rrIntervals.map(interval => interval * 1000),
                borderColor: '#4CAF50',
                borderWidth: 2,
                fill: false,
                tension: 0.1, // Smooth curve
                pointStyle: 'cross', // Change points to crosses

            },
        ],
    }), [labels, rrIntervals]);

    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Interval Index',
                },
            },
            y: {
                title: {
                    display: true,
                    text: t('ecg.intervalDuration'),
                },
                min: 200,
                max: 1500,
                ticks: {
                    callback: function (value) {
                        return Math.round(value);
                    },
                },
            },
        },
    }), [t]);

    // Render the chart
    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (chartRef.current) {
            chartInstanceRef.current = new Chart(chartRef.current, {
                type: 'line',
                data: data,
                options: options,
            });
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [data, options]);

    return (
        <Accordion defaultExpanded={false} sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}> {/* Accordéon replié par défaut */}
                <Typography variant="h5">R-R Intervals Chart</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ borderRadius: 4, p: 3, boxShadow: 3 }}>
                    <CardContent>
                        <Box width="100%" height="400px">
                            <canvas ref={chartRef} />
                        </Box>
                    </CardContent>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default RRIntervalsChart;
