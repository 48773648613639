import React from 'react';
import {
    IconButton,
} from '@mui/material';


import { ChevronLeft } from '@mui/icons-material';


const MoveLeftGraphArrow = ({  setPanOffset }) => {
   
    const handlePanLeft = () => {
        setPanOffset((prev) => Math.max(0, prev - 200));
    };

    return (

        <IconButton
            onClick={handlePanLeft}
            style={{
                position: 'absolute',
                left: 10,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 10
            }}
        >
            <ChevronLeft />
        </IconButton>
    );
};
export default MoveLeftGraphArrow;