import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Container,
  CircularProgress,
} from '@mui/material';
import {
  Person,
  Favorite,
  Warning,
} from '@mui/icons-material';
import { fetchPatients } from '../api/Patient';
import { fetchPatientECGs } from '../api/ECG';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const Dashboard = () => {
  const { t } = useTranslation();
  const [recentViewedECGs, setRecentViewedECGs] = useState([]);
  const [patients, setPatients] = useState([]);
  const [ecgCount, setEcgCount] = useState(0);
  const [normalSinusRhythmCount, setNormalSinusRhythmCount] = useState(0);
  const [atrialFibrillationCount, setAtrialFibrillationCount] = useState(0);
  const [otherECGCount, setOtherECGCount] = useState(0);
  const [loadingPatients, setLoadingPatients] = useState(true);
  const [loadingECGs, setLoadingECGs] = useState(true);
  const navigate = useNavigate();

  let userName = '';
  try {
    const token = Cookies.get('jwt');
    if (token) {
      const decodedToken = jwtDecode(token);
      userName = decodedToken.given_name ? decodedToken.given_name : 'Utilisateur';
    }
  } catch (error) {
    console.error('Erreur lors du décodage du token JWT :', error);
  }

  useEffect(() => {
    const getData = async () => {
      try {
        // Fetch Patients
        const patientResponse = await fetchPatients();
        if (patientResponse && patientResponse.data && Array.isArray(patientResponse.data.patients)) {
          const { patients, recentViewedECGs } = patientResponse.data;
          const formattedData = patients.map((patient, index) => ({
            id: patient.id || index,
            ...patient,
          }));
          setPatients(formattedData);
          if (recentViewedECGs) {
            setRecentViewedECGs(recentViewedECGs);
          }
        } else {
          console.error('Format inattendu des données :', patientResponse);
        }
        setLoadingPatients(false);

        // Fetch ECGs
        const ecgResponse = await fetchPatientECGs();
        if (ecgResponse && ecgResponse.data && Array.isArray(ecgResponse.data)) {
          const ecgs = ecgResponse.data;
          let totalECGs = ecgs.length;
          let normalSinusRhythm = 0;
          let atrialFibrillation = 0;
          let otherECGs = 0;

          ecgs.forEach((ecg) => {
            if (ecg && ecg.ECGStatus) {
              switch (ecg.ECGStatus) {
                case 'ClinicalStatus.NORMAL_SINUS_RHYTHM':
                case 'ClinicalStatus.SINUS_TACHYCARDIA':
                  normalSinusRhythm++;
                  break;
                case 'ClinicalStatus.ATRIAL_FIBRILLATION':
                  atrialFibrillation++;
                  break;
                default:
                  otherECGs++;
                  break;
              }
            }
          });

          setEcgCount(totalECGs);
          setNormalSinusRhythmCount(normalSinusRhythm);
          setAtrialFibrillationCount(atrialFibrillation);
          setOtherECGCount(otherECGs);
        } else {
          console.error('Format inattendu des données ECG :', ecgResponse);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      } finally {
        setLoadingECGs(false);
      }
    };

    getData();
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '100vh',
        bgcolor: 'linear-gradient(135deg, #f3e5f5 30%, #e8f5e9 90%)',
        p: 3,
      }}
    >
      <Typography variant="h5" align="center" sx={{ mb: 4, color: '#333' }}>
        {userName ? t('dashboard.welcome_message', { name: userName }) : t('dashboard.default_welcome_message')}
      </Typography>
      <Typography variant="h5" align="center" sx={{ mb: 4, color: '#333' }}>
        {t('dashboard.feel_at_home')}
      </Typography>

      <Box display="flex" justifyContent="space-between" flexWrap="wrap" sx={{ gap: 4 }}>
        <Box flexBasis={{ xs: '100%', md: '27%' }}>
          <Card sx={{ p: 3, boxShadow: 3, borderRadius: 4, bgcolor: 'transparent' }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, color: '#333' }}>
                {t('dashboard.patient_summary')}
              </Typography>
              <QuickStatsCard
                title={t('dashboard.active_patients')}
                value={loadingPatients ? <CircularProgress size={24} /> : patients.length}
                icon={Person}
                color="#4caf50"
                onClick={() => navigate('/patients')}
              />
            </CardContent>
          </Card>
        </Box>

        <Box flexBasis={{ xs: '100%', md: '70%' }}>
          <Card sx={{ p: 3, boxShadow: 3, borderRadius: 4, bgcolor: 'transparent' }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, color: '#333' }}>
                {`${ecgCount} ${t('dashboard.total_ecgs_analyzed')}`}
              </Typography>
              <Box display="flex" justifyContent="space-around" flexWrap="wrap" sx={{ gap: 2 }}>
                <QuickStatsCard
                  title={t('dashboard.sinusal')}
                  value={loadingECGs ? <CircularProgress size={24} /> : normalSinusRhythmCount}
                  icon={Favorite}
                  color="#4caf50"
                  onClick={() => navigate('/ecg')}
                />
                <QuickStatsCard
                  title={t('ecg.status.ClinicalStatus.ATRIAL_FIBRILLATION')}
                  value={loadingECGs ? <CircularProgress size={24} /> : atrialFibrillationCount}
                  icon={Warning}
                  color="#f44336"
                  onClick={() => navigate('/ecg')}
                />
                <QuickStatsCard
                  title={t('dashboard.other_status')}
                  value={loadingECGs ? <CircularProgress size={24} /> : otherECGCount}
                  icon={Warning}
                  color="#ff9800"
                  onClick={() => navigate('/ecg')}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Container>
  );
};

const QuickStatsCard = ({ title, value, icon: Icon, color, onClick }) => (
  <Card onClick={onClick} sx={{ bgcolor: color, color: 'white', p: 3, boxShadow: 3, borderRadius: 4, cursor: 'pointer', flex: '1 1 30%' }}>
    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Icon sx={{ fontSize: 50, mb: 1 }} />
      <Typography variant="h5" sx={{ mb: 1 }}>{value}</Typography>
      <Typography variant="subtitle1" align="center">{title}</Typography>
    </CardContent>
  </Card>
);

export default Dashboard;
