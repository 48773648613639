import axios from 'axios';
import Cookies from 'js-cookie';

const API_PATIENTS_URL = 'https://api.emcardia.com/prod/patient';
const API_KEY = 'QZtBbIjzTQ7zVc3gKQxYE9EOy3Z99PWE10lYtgY5';

// Créer une instance Axios
const apiClient = axios.create({
    baseURL: API_PATIENTS_URL,
});

// Intercepteur pour ajouter automatiquement le token à chaque requête
apiClient.interceptors.request.use((config) => {
    const token = Cookies.get('jwt');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['x-api-key'] = API_KEY; // Ajoute l'API key à chaque requête
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Utilisation de l'instance personnalisée
export const fetchPatients = () => apiClient.get('');
export const putPatients = (data) => apiClient.put('', data);
export const postPatients = (data) => apiClient.post('', data);
