import React, { useState, useEffect, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Cookies from 'js-cookie';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Layout from './Layout';
import Patient from './pages/Patient';
import Participate from './pages/Participate';
import UserPool from './UserPool';
import VerifyEmail from './pages/VerifyEmail';
import { jwtDecode } from 'jwt-decode';
import EcgDetailPage from './pages/EcgDetailPage';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');

  const handleTokenRefresh = useCallback(() => {
    const cognitoUser = UserPool.getCurrentUser();
  
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error("Session retrieval failed:", err);
          setIsAuthenticated(false);
          setIsLoading(false);
        } else {
          if (session.isValid()) {
            cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
              if (err) {
                console.error("Failed to refresh token:", err);
                setIsAuthenticated(false);
                setIsLoading(false);
              } else {
                const newIdToken = session.getIdToken().getJwtToken();
                const refreshToken = session.getRefreshToken().getToken();
  
                // Met à jour les cookies
                Cookies.set('jwt', newIdToken, {
                  path: '/',
                  expires: 1,
                  sameSite: 'Lax',
                  secure: window.location.protocol === 'https:'
                });
                Cookies.set('refresh_token', refreshToken, {
                  path: '/',
                  expires: 30,
                  sameSite: 'Lax',
                  secure: window.location.protocol === 'https:'
                });
  
                setIsAuthenticated(true);
                setIsLoading(false);
              }
            });
          } else {
            console.warn("Refresh token has expired.");
            handleLogout();
          }
        }
      });
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);
  
  useEffect(() => {
    const checkAuthentication = () => {
      const token = Cookies.get('jwt');
  
      if (!token || token.split('.').length !== 3) {
        setIsLoading(false);
        setIsAuthenticated(false);
        return;
      }
  
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
  
        if (decodedToken.exp < currentTime) {
          console.warn("Token has expired.");
          handleTokenRefresh();
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        setIsAuthenticated(false);
      }
  
      setIsLoading(false);
    };
  
    checkAuthentication();
  
    const intervalId = setInterval(checkAuthentication, 10000);
  
    return () => clearInterval(intervalId);
  }, [handleTokenRefresh]);
  
  
  

  const handleLogin = (username, password) => {
    setLoadingMessage('Authenticating...');
    setIsLoading(true);
    const user = new CognitoUser({ Username: username, Pool: UserPool });
    const authDetails = new AuthenticationDetails({ Username: username, Password: password });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const jwtToken = result.getAccessToken().getJwtToken();
        Cookies.set('jwt', jwtToken, { path: '/', expires: 1, sameSite: 'Lax', secure: window.location.protocol === 'https:' });
        setIsAuthenticated(true);
        setLoadingMessage('');
        setIsLoading(false);
      },
      onFailure: (err) => {
        console.error('Authentication failed:', err);
        setLoadingMessage('');
        setIsLoading(false);
      },
    });
  };

  const handleLogout = () => {
    const token = Cookies.get('jwt');
    if (token) {
      const user = new CognitoUser({ Username: 'username', Pool: UserPool });
      user.signOut();
      Cookies.remove('jwt', { path: '/' });
      setIsAuthenticated(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <p>{loadingMessage}</p>
      </div>
    );
  }

  return (
    <Router>
      <AppRoutes
        isAuthenticated={isAuthenticated}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
      />
    </Router>
  );
}

function AppRoutes({ isAuthenticated, handleLogin, handleLogout, showSessionModal, setShowSessionModal }) {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Layout isAuthenticated={isAuthenticated} onLogout={() => handleLogout()}>
            <Routes>
              <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Home />} />
              <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login onLogin={(username, password) => handleLogin(username, password)} />} />
              <Route path="/participate" element={<Participate />} />
              <Route path="/verify" element={<VerifyEmail />} />
              <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />} />
              <Route path="/patients" element={isAuthenticated ? <Patient /> : <Navigate to="/login" replace />} />
              <Route path="/ecg" element={isAuthenticated ? <EcgDetailPage /> : <Navigate to="/login" replace />} />
              <Route path="/ecg/:patientCode/:ecgId" element={isAuthenticated ? <EcgDetailPage /> : <Navigate to="/login" replace />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Layout>
        }
      />
    </Routes>
  );
}

export default App;
