import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: 'eu-west-3_GVksKsg9e',
  ClientId: '138glr6u8u778ra0md1h4c9ibq'
};

const userPool = new CognitoUserPool(poolData);

export default userPool;
