import React from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

const RecentViewedECGs = ({ recentViewedECGs, ecgList }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage;


  if (!recentViewedECGs || recentViewedECGs.length === 0) {
    return <Typography variant="body1">{t('No recently viewed ECGs available')}</Typography>;
  }

  return (
    <Box sx={{ padding: 3, borderRadius: 2 }}>
      <Typography variant="h5" sx={{ color: '#333', marginBottom: 2 }}>{t('ecg.lastViewed')}</Typography>
      <List>
        {recentViewedECGs.map((recentEcg, index) => {
          const ecg = ecgList.find(e => e.id === recentEcg.ecgId);
          if (!ecg) {
            return null;
          }
          return (
            <ListItem key={index} sx={{ marginBottom: 1 }}>
              <ListItemIcon>
                <IconButton>
                  <FavoriteIcon sx={{ color: '#ff4757' }} />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <Link to={`/ecg/${ecg.patientCode}/${ecg.id}`} style={{ textDecoration: 'none', color: '#007bff', fontWeight: 'bold' }}>
                      {t('ECG')} {t('ecg.forPatient')} {ecg.patientCode} {t('ecg.recorded')} {new Date(ecg.startDate).toLocaleString(language, { dateStyle: 'short', timeStyle: 'short' })} {t('ecg.withStatus')} {t("ecg.status."+ecg.ECGStatus)}
                    </Link> 
                  </>
                }
                secondary={`${t('ecg.viewed')} ${formatDistanceToNow(parseISO(recentEcg.viewedAt), { addSuffix: true, locale: language === 'fr' ? fr : enUS })}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default RecentViewedECGs;
