import React from 'react';
import {

    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

const PatientSelector = ({ patients, selectedPatient, handlePatientChange }) => {

    const { t } = useTranslation();

    return (

        <>
            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                {t('patients.patientsList')}
            </Typography>

            <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
                <InputLabel>{t('patients.selectPatient')}</InputLabel>
                <Select
                    value={selectedPatient}
                    onChange={handlePatientChange}
                    label={t('patients.selectPatient')}
                >
                    {patients.map((patient) => (
                        <MenuItem key={patient.patientCode} value={patient.patientCode}>
                            {patient.patientCode}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default PatientSelector;