import React from 'react';
import {
    Box,
    Paper,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    TextField,
    Button,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment'; // Icône pour les examens complémentaires

import DeleteIcon from '@mui/icons-material/Delete'; // Icône de suppression
import { useTranslation } from 'react-i18next';

const ComplementaryExamsForm = ({ formData, setFormData }) => {

    const { t } = useTranslation();

    // Mettre à jour handleExamChange pour modifier correctement la valeur "selected" dans exams
    const handleExamChange = (e, exam) => {
        const { checked } = e.target;

        setFormData(prevFormData => {
            const updatedExam = {
                ...prevFormData.exams?.[exam],
                selected: checked,
            };

            // Ajouter une conclusion initiale si l'examen est sélectionné et qu'il n'y en a pas
            if (checked && (!updatedExam.conclusions || updatedExam.conclusions.length === 0)) {
                updatedExam.conclusions = [{ date: '', text: '' }];
            }

            return {
                ...prevFormData,
                exams: {
                    ...prevFormData.exams,
                    [exam]: updatedExam,
                },
            };
        });
    };

    // Mettre à jour handleExamConclusionChange pour gérer les conclusions
    const handleExamConclusionChange = (e, exam, conclusionIndex = null, addNew = false) => {
        if (addNew) {
            setFormData(prevFormData => ({
                ...prevFormData,
                exams: {
                    ...prevFormData.exams,
                    [exam]: {
                        ...prevFormData.exams?.[exam],
                        conclusions: [...(prevFormData.exams?.[exam]?.conclusions || []), { date: '', text: '' }],
                    },
                },
            }));
            return;
        }

        const { name, value } = e.target;
        const field = name.includes('Date') ? 'date' : 'text';

        setFormData(prevFormData => ({
            ...prevFormData,
            exams: {
                ...prevFormData.exams,
                [exam]: {
                    ...prevFormData.exams?.[exam],
                    conclusions: prevFormData.exams?.[exam]?.conclusions.map((conclusion, index) =>
                        index === conclusionIndex ? { ...conclusion, [field]: value } : conclusion
                    ),
                },
            },
        }));
    };

    // Supprimer une conclusion
    const handleDeleteConclusion = (exam, conclusionIndex) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            exams: {
                ...prevFormData.exams,
                [exam]: {
                    ...prevFormData.exams?.[exam],
                    conclusions: prevFormData.exams?.[exam]?.conclusions.filter((_, index) => index !== conclusionIndex),
                },
            },
        }));
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Paper elevation={3} sx={{ width: '100%', padding: 3 }}>
                <Box display="flex" alignItems="center" mb={2}>
                    <AssignmentIcon sx={{ color: '#1976d2', fontSize: 30, marginRight: 1 }} />
                    <Typography variant="h6" component="div">
                        {t('patients.complementaryExams')}
                    </Typography>

                </Box>
                <Divider sx={{ mb: 2 }} />
                <FormControl component="fieldset" sx={{ width: '100%' }}>
                    <FormGroup sx={{ width: '100%' }}>
                        {['echocardiography', 'electrophysiology', 'stressTest', 'otherExam'].map((exam, index) => (
                            <Box key={index} sx={{ mb: 3, width: '100%' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={exam}
                                            checked={formData.exams?.[exam]?.selected || false}
                                            onChange={(e) => handleExamChange(e, exam)}
                                            disabled={formData.exams?.[exam]?.conclusions?.length > 0}
                                        />
                                    }
                                    label={t(`patients.exams.${exam}`)}
                                />
                                {/* Si l'examen est sélectionné, afficher la date et les détails sous forme de tableau */}
                                {formData.exams?.[exam]?.selected && (
                                    <TableContainer sx={{ mt: 1, width: '100%' }}>
                                        <Table sx={{ width: '100%' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t('patients.examDate')}</TableCell>
                                                    <TableCell>{t('patients.examConclusionTitle')}</TableCell>
                                                    <TableCell align="right">{t('patients.actions')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {formData.exams?.[exam]?.conclusions?.map((conclusion, conclusionIndex) => (
                                                    <TableRow key={conclusionIndex} sx={{ width: '100%' }}>
                                                        <TableCell sx={{ width: '20%' }}>
                                                            <TextField
                                                                type="date"
                                                                name={`${exam}Date-${conclusionIndex}`}
                                                                value={conclusion.date || ''}
                                                                onChange={(e) => handleExamConclusionChange(e, exam, conclusionIndex)}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ width: '75%' }}>
                                                            <TextField
                                                                name={`${exam}Conclusion-${conclusionIndex}`}
                                                                value={conclusion.text || ''}
                                                                multiline
                                                                rows={3}
                                                                onChange={(e) => handleExamConclusionChange(e, exam, conclusionIndex)}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ width: '5%' }} align="right">
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => handleDeleteConclusion(exam, conclusionIndex)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell colSpan={3}>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => handleExamConclusionChange(null, exam, formData.exams?.[exam]?.conclusions?.length || 0, true)}
                                                            sx={{ mt: 1 }}
                                                            fullWidth
                                                        >
                                                            {t('patients.addConclusion')}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Box>
                        ))}
                    </FormGroup>
                </FormControl>
            </Paper>
        </Box>


    );
};

export default ComplementaryExamsForm;