import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Link } from '@mui/material';
import heroImage from '../assets/images/image.png';
import analysisImage from '../assets/images/analysis.jpg'; 
import captureImage from '../assets/images/capture.jpg'; 
import smartwatchImage from '../assets/images/smartwatch.jpg'; 
import aiImage from '../assets/images/ai.jpg'; 
import PollIcon from '@mui/icons-material/Poll';

import '../assets/css/styles.css';

function Home() {
  const { t } = useTranslation();
  const carouselItems = t('home.carousel', { returnObjects: true });

  const images = [analysisImage, captureImage, smartwatchImage, aiImage];
  const sectionBackgrounds = ['#f0f8ff', '#f5f5f5', '#faf0e6', '#f0fff0']; // Couleurs d'arrière-plan douces

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', px: 2 }}>
      {/* Section Héro */}
      <Box
        component="section"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 5,
          mb: 10, // Plus de marge après le héro
          maxWidth: '1200px',
          mx: 'auto',
          textAlign: 'center',
          flexDirection: { xs: 'column', md: 'row' }, // Image et texte côte à côte sur grand écran
        }}
      >
        <Box
          component="img"
          src={heroImage}
          alt="Hero"
          sx={{
            width: { xs: '100%', md: '50%' },
            height: 'auto',
            borderRadius: '16px',
            mb: { xs: 3, md: 0 },
            mr: { md: 3 },
          }}
        />
        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
          <Typography variant="h3" color="textPrimary" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t('home.heroTitle')}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {t('home.heroSubtitle')}
          </Typography>
        </Box>
      </Box>

      {/* Section du Sondage - déplacée juste après la section Héro */}
      <Box
        component="section"
        sx={{
          py: 5,
          // Suppression du backgroundColor
        }}
      >
        <Box
          sx={{
            maxWidth: '800px',
            mx: 'auto',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            px: 2,
          }}
        >
          <PollIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
          <Typography variant="h5" color="textPrimary">
            {t('home.surveyMessage')}
            <Link href="/participate" sx={{ ml: 1 }}>
              {t('home.surveyLink')}
            </Link>
          </Typography>
        </Box>
      </Box>

      {/* Sections avec alternance d'images et de textes décalées */}
      {carouselItems.map((item, index) => (
        <Box
          key={index}
          component="section"
          sx={{
            py: 5,
            // Pas de backgroundColor sur le Box externe
          }}
        >
          <Box
            sx={{
              maxWidth: '1200px',
              mx: 'auto',
              backgroundColor: sectionBackgrounds[index % sectionBackgrounds.length], // Application de la couleur de fond sur le conteneur interne
              borderRadius: '16px',
              display: 'flex',
              flexDirection: { xs: 'column', md: index % 2 === 0 ? 'row' : 'row-reverse' },
              alignItems: 'center',
              px: 2,
              py: 3,
              position: 'relative',
              left: { md: index % 2 === 0 ? '-60px' : '60px' }, // Décalage à gauche ou à droite sur les grands écrans
            }}
          >
            <Box
              component="img"
              src={images[index]}
              alt={item.problem}
              sx={{
                width: { xs: '100%', md: '50%' },
                height: 'auto',
                borderRadius: '16px',
                mb: { xs: 3, md: 0 },
                mx: { md: 3 },
              }}
            />
            <Box sx={{ width: { xs: '100%', md: '50%' }, textAlign: { xs: 'center', md: 'left' } }}>
              <Typography variant="h5" className="problem" sx={{ mb: 2, fontWeight: 'bold' }}>
                {item.problem}
              </Typography>
              <Typography variant="body1" className="solution">
                {item.solution}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}

    </Box>
  );
}

export default Home;
