import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography, Stack, Card, CardContent, CardActionArea, Link } from '@mui/material';
import { LocalHospital, HeartBroken, MedicalServices } from '@mui/icons-material';

const Participate = () => {
  const { t, i18n } = useTranslation();

  // Liens pour chaque spécialité en fonction de la langue
  const formLinks = {
    fr: {
      infirmier: 'https://forms.gle/UGDF6m7xEHJS37CW7',
      medecin: 'https://forms.gle/QxjLcxmvcSAXaTJJ6',
      cardio_rythmo: 'https://forms.gle/FYBSMn3Sajk1niaQ7',
    },
    en: {
      infirmier: 'https://forms.gle/UGDF6m7xEHJS37CW7',
      medecin: 'https://forms.gle/QxjLcxmvcSAXaTJJ6',
      cardio_rythmo: 'https://forms.gle/FYBSMn3Sajk1niaQ7',
    },
  };

  const lang = i18n.language.split('-')[0];
  const links = formLinks[lang] || formLinks.en;

  return (
    <Container sx={{ marginTop: 4, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        {t('participate.title')}
      </Typography>
      <Typography variant="h6" color="text.secondary">
        {t('participate.description')}
      </Typography>
      <Typography variant="h6" color="text.secondary">
        {t('participate.duration')}
      </Typography>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: 4 }}
      >
        {/* Infirmiers / Infirmières */}
        <Card sx={cardStyle}>
          <Link href={links.infirmier} underline="none" target="_blank" rel="noopener noreferrer">
            <CardActionArea>
              <CardContent sx={{ textAlign: 'center', padding: 2 }}>
                <MedicalServices fontSize="large" color="primary" />
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  {t('specialties.nurse')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('participate.click_here')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>

        {/* Médecin Généraliste */}
        <Card sx={cardStyle}>
          <Link href={links.medecin} underline="none" target="_blank" rel="noopener noreferrer">
            <CardActionArea>
              <CardContent sx={{ textAlign: 'center', padding: 2 }}>
                <LocalHospital fontSize="large" color="primary" />
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  {t('specialties.gp')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('participate.click_here')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>

        {/* Cardiologue / Rythmologue */}
        <Card sx={cardStyle}>
          <Link href={links.cardio_rythmo} underline="none" target="_blank" rel="noopener noreferrer">
            <CardActionArea>
              <CardContent sx={{ textAlign: 'center', padding: 2 }}>
                <HeartBroken fontSize="large" color="error" />
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  {t('specialties.cardiologist')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('participate.click_here')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </Stack>
    </Container>
  );
};

const cardStyle = {
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  transition: '0.3s',
  cursor: 'pointer',
  width: '320px',
  height: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export default Participate;
