import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    Paper,
    Divider,
} from '@mui/material';

import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

const ArrhythmiaForm = ({ formData, setFormData, handleInputChange }) => {
    const { t } = useTranslation();

    useEffect(() => {
        // Log initial formData when component mounts
        console.log('Initial formData:', formData);
    }, [formData]);

    const handleTriggerChange = (e) => {
        const { value, checked } = e.target;
        const updatedTriggers = checked
            ? [...formData.triggers, value]
            : formData.triggers.filter((trigger) => trigger !== value);

        console.log('Updated triggers:', updatedTriggers);

        setFormData({ ...formData, triggers: updatedTriggers });
    };

    const handleManeuverChange = (e) => {
        const { value, checked } = e.target;

        let updatedManeuvers = [];
        if (value === t('patients.maneuverList.none')) {
            updatedManeuvers = checked ? [value] : [];
        } else {
            if (checked) {
                updatedManeuvers = [...formData.maneuvers.filter(m => m !== t('patients.maneuverList.none')), value];
            } else {
                updatedManeuvers = formData.maneuvers.filter((maneuver) => maneuver !== value);
            }
        }

        console.log('Updated maneuvers:', updatedManeuvers);

        setFormData({ ...formData, maneuvers: updatedManeuvers });
    };

    const handleHasArrhythmiaChange = (e) => {
        const { name, value } = e.target;
        let arrhythmiaPersistence = '';
        let crisisDuration = '';
        let crisisDurationUnit = '';
        let triggers = [];
        let maneuvers = [];
        let maneuverTiming = '';

        const updatedFormData = {
            ...formData,
            [name]: value,
            arrhythmiaPersistence,
            crisisDuration,
            crisisDurationUnit,
            triggers,
            maneuvers,
            maneuverTiming,
        };

        console.log('Updated formData after arrhythmia change:', updatedFormData);

        setFormData(updatedFormData);
    };

    const handlePersistenceChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value || ''
        };

        console.log('Updated formData after persistence change:', updatedFormData);

        setFormData(updatedFormData);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <Paper elevation={3} sx={{ borderRadius: 2, padding: 3, width: '100%' }}>
                <Box display="flex" alignItems="center" mb={2}>
                    <MonitorHeartIcon sx={{ color: '#4caf50', fontSize: 30, marginRight: 1 }} />
                    <Typography variant="h6" component="div">
                        {t('patients.arrhythmiaTitle')}
                    </Typography>
                </Box>

                <Divider sx={{ mb: 2 }} />


                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* Colonne Gauche */}
                    <Box flex={1} mr={2}>
                        {/* Question sur les Arythmies */}
                        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                            <InputLabel>{t('patients.arrhythmia')}</InputLabel>
                            <Select
                                label={t('patients.arrhythmia')}
                                name="arrhythmia"
                                value={formData.arrhythmia || ''}
                                onChange={handleHasArrhythmiaChange}
                            >
                                <MenuItem value="yes">{t('patients.yes')}</MenuItem>
                                <MenuItem value="no">{t('patients.no')}</MenuItem>
                            </Select>
                        </FormControl>

                        {formData.arrhythmia === 'yes' && (
                            <>
                                {/* Persistence of Arrhythmias */}
                                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                                    <InputLabel>{t('patients.arrhythmiaPersistence')}</InputLabel>
                                    <Select
                                        label={t('patients.arrhythmiaPersistence')}
                                        name="arrhythmiaPersistence"
                                        value={formData.arrhythmiaPersistence || ''}
                                        onChange={handlePersistenceChange}
                                    >
                                        <MenuItem value="paroxysmal">{t('patients.paroxysmal')}</MenuItem>
                                        <MenuItem value="persistent">{t('patients.persistent')}</MenuItem>
                                    </Select>
                                </FormControl>

                                {/* Crisis Duration */}
                                {formData.arrhythmiaPersistence !== 'persistent' && (
                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <Typography variant="subtitle1">{t('patients.durationOfCrises')}</Typography>
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                type="number"
                                                name="crisisDuration"
                                                value={formData.crisisDuration || ''}
                                                onChange={handleInputChange}
                                                sx={{ marginRight: 1 }}
                                            />
                                            <FormControl>
                                                <Select
                                                    name="crisisDurationUnit"
                                                    value={formData.crisisDurationUnit || ''}
                                                    onChange={handleInputChange}
                                                >
                                                    <MenuItem value="min">{t('patients.minutes')}</MenuItem>
                                                    <MenuItem value="hours">{t('patients.hours')}</MenuItem>
                                                    <MenuItem value="days">{t('patients.days')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </FormControl>
                                )}

                                {/* Maneuver Timing */}
                                {formData.maneuvers.length > 0 &&
                                    !formData.maneuvers.includes(t('patients.maneuverList.none')) && (
                                        <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                                            <InputLabel>{t('patients.whenManeuverEffective')}</InputLabel>
                                            <Select
                                                label={t('patients.whenManeuverEffective')}
                                                name="maneuverTiming"
                                                value={formData.maneuverTiming || ''}
                                                onChange={handleInputChange}
                                            >
                                                <MenuItem value="startOfCrisis">{t('patients.startOfCrisis')}</MenuItem>
                                                <MenuItem value="anytime">{t('patients.anytime')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                            </>
                        )}
                    </Box>

                    {/* Colonne Droite */}
                    <Box flex={1} ml={2}>
                        {formData.arrhythmia === 'yes' && (
                            <>
                                {/* Triggers */}
                                <FormControl component="fieldset" sx={{ mb: 4 }}>
                                    <Typography variant="subtitle1">{t('patients.triggers')}</Typography>
                                    <FormGroup>
                                        {['physicalActivity', 'stress', 'excitants', 'fever', 'positionChange'].map((triggerKey, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        value={t(`patients.triggerList.${triggerKey}`)}
                                                        onChange={handleTriggerChange}
                                                        checked={formData.triggers.includes(t(`patients.triggerList.${triggerKey}`))}
                                                    />
                                                }
                                                label={t(`patients.triggerList.${triggerKey}`)}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>

                                {/* Maneuvers to Stop Crises */}
                                <FormControl component="fieldset">
                                    <Typography variant="subtitle1">{t('patients.maneuversToStopCrises')}</Typography>
                                    <FormGroup>
                                        {['respiration', 'relaxation', 'lyingDown', 'vagalManeuver', 'none'].map((maneuverKey, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        value={t(`patients.maneuverList.${maneuverKey}`)}
                                                        onChange={handleManeuverChange}
                                                        checked={formData.maneuvers.includes(t(`patients.maneuverList.${maneuverKey}`))}
                                                        disabled={
                                                            formData.maneuvers.includes(t('patients.maneuverList.none')) && maneuverKey !== 'none'
                                                        }
                                                    />
                                                }
                                                label={t(`patients.maneuverList.${maneuverKey}`)}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </>
                        )}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default ArrhythmiaForm;
